import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import ClubCard from './ClubCard'
import OrderBadge from './OrderBadge'

export function SortableClubItem({ id, item, index }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  })

  const style = {
    position: 'relative',
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    height: '100%',
    cursor: isDragging ? 'grabbing' : 'grab',
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <OrderBadge order={index + 1} />
      <div
        style={{
          height: '100%',
          transition: isDragging ? 'none' : 'transform 0.3s ease',
          transform: isDragging ? 'scale(1.03)' : 'scale(1)',
        }}
      >
        <ClubCard featuredHomepageClub={item} isReordering />
      </div>
    </div>
  )
}
