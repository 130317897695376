import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import { countries } from 'countries-list'
import { Table, Dropdown, Modal, Menu, Form, Input, Typography } from 'antd'
import { EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { actions as acts, US_STATES } from '../../../constants'
import ImagePreview from '../../../components/ImagePreview'
import CountrySelector from '../../../components/CountrySelector'
import StateSelector from '../../../components/StateSelector'
import Attr from '../../../components/Attr'
import { Reorderable } from '../../../components/Reorderable'
import { Head, Button, Cta, Title, Label, Noner } from '../../../components/common'
import SingleImageEditor from '../../../components/SingleImageEditor'
import get from 'lodash.get'
import {
  updateBtlFeaturedGeo,
  createBtlFeaturedGeo,
  fetchBtlFeaturedGeos,
  deleteBtlFeaturedGeo,
  reorderBtlFeaturedGeos,
} from '../../../actions/admin'
import { useDispatch } from 'react-redux'

const { Paragraph } = Typography

const initState = {
  title: null,
  country: 'US',
  state: null,
  city: null,
  image: null,
}

const FeaturedGeoEditor = ({ onChange, geo }) => {
  const theme = useContext(ThemeContext)
  if (!geo) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { title, country, state, city, image } = geo

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...geo, ...update })
    }
  }
  return (
    <Form layout="vertical">
      <Form.Item name="title" label={<Label>Title</Label>} initialValue={title}>
        <Input placeholder="" onChange={e => change({ title: e.target.value })} value={title} />
      </Form.Item>

      <Form.Item name="country" label={<Label>Country</Label>} initialValue={country}>
        <CountrySelector
          id="country"
          onChange={v => change({ country: v })}
          value={country}
          style={{ width: theme.width[5] }}
        />
      </Form.Item>

      <Form.Item name="state" label={<Label>State</Label>} initialValue={state}>
        <StateSelector
          id="state"
          onChange={v => change({ state: v })}
          value={state}
          style={{ width: theme.width[5] }}
        />
      </Form.Item>

      <Form.Item name="city" label={<Label>City</Label>} initialValue={city}>
        <Input
          placeholder=""
          onChange={e => change({ city: e.target.value })}
          style={{ width: theme.width[5] }}
          value={city}
        />
      </Form.Item>
      <Form.Item name="image" label={<Label>Image</Label>}>
        <SingleImageEditor
          actionText="Select image"
          onChange={img => change({ image: img })}
          img={image}
        />
      </Form.Item>
    </Form>
  )
}

const CreateFeaturedGeoModal = ({ complete }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [geo, setGeo] = useState(null)
  const status = {
    create: useStatus(acts.CREATE_BTL_FEATURED_GEO),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_BTL_FEATURED_GEO))
    }
  }, [])

  useStatusMsg(status.create, { error: 'Failed to create featured geo' })

  const onOk = async () => {
    dispatch(createBtlFeaturedGeo(geo))
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete =
    geo && [geo.title, geo.country, geo.state, geo.image].reduce((acc, curr) => curr && acc, true)

  return (
    <Modal
      title={'Create featured geo'}
      visible
      okText={'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.create.pending || false}
    >
      <FeaturedGeoEditor geo={geo} onChange={updatedGeo => setGeo(updatedGeo)} />
    </Modal>
  )
}

const EditFeaturedGeoModal = ({ complete, geo }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [featuredGeo, setFeaturedGeo] = useState(geo)
  const status = {
    update: useStatus(acts.UPDATE_BTL_FEATURED_GEO),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_BTL_FEATURED_GEO))
    }
  }, [])

  useStatusMsg(status.update, { error: 'Failed to update featured geo' })

  const onOk = async () => {
    const params = { geoId: geo.id, ...featuredGeo }
    // if image hasn't changed, just append the existing image id to the request
    if (!(params.image instanceof File)) {
      params.image = get(params.image, 'id', params.image)
    }
    dispatch(updateBtlFeaturedGeo(params))
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete =
    featuredGeo &&
    [featuredGeo.title, featuredGeo.country, featuredGeo.state, featuredGeo.image].reduce(
      (acc, curr) => curr && acc,
      true,
    )

  return (
    <Modal
      title={'Edit featured geo'}
      visible
      okText={'Save'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.update.pending || false}
    >
      <FeaturedGeoEditor geo={featuredGeo} onChange={updatedGeo => setFeaturedGeo(updatedGeo)} />
    </Modal>
  )
}

const columns = ({ editGeo, deleteGeo }) => [
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    render: (val, record) => {
      const country = countries[val]
      return <Noner>{country && country.name}</Noner>
    },
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (val, record) => {
      const state = US_STATES[val]
      return <Noner>{state}</Noner>
    },
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (val, record) => (
      <div>
        <Noner
          none="No image"
          cond={() => {
            console.log(val)
            return !!val
          }}
        >
          <ImagePreview asset={val} width="150px" height="150px" style={{ objectFit: 'cover' }} />
        </Noner>
        {!val && (
          <ImagePreview asset={val} width="150px" height="150px" style={{ objectFit: 'cover' }} />
        )}
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '10',
    render: (val, record) => (
      <Dropdown
        overlay={<ActionsMenu geo={record} onDelete={deleteGeo} onEdit={editGeo} />}
        trigger={['click']}
      >
        <EllipsisOutlined
          className="ant-dropdown-link"
          style={{ fontSize: '1.75em', fontWeight: 'bold' }}
        />
      </Dropdown>
    ),
  },
]

const ActionsMenu = ({ geo, onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_BTL_FEATURED_GEO)

  useStatusMsg(deleteStatus, {
    pending: 'Deleting featured geo...',
    error: 'Failed to delete featured geo',
    success: 'Featured geo deleted',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_BTL_FEATURED_GEO)), [])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(geo)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete featured geo?',
        okText: 'Delete',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false,
          disabled: deleteStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to delete this featured geo?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(deleteBtlFeaturedGeo({ geoId: geo.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(geo)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

const FeaturedGeo = ({ geo }) => {
  const country = countries[geo.country]
  const state = US_STATES[geo.state]
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Attr name="Title">
        <div>{geo.title}</div>
      </Attr>
      <Attr name="Country">
        <div>
          <Noner>{country && country.name}</Noner>
        </div>
      </Attr>
      <Attr name="State">
        <div>
          <Noner>{state}</Noner>
        </div>
      </Attr>
      <Attr name="City">
        <div>{geo.city}</div>
      </Attr>
      <Attr name="Image">
        <div>
          <ImagePreview
            asset={geo.image}
            width="150px"
            height="150px"
            style={{ objectFit: 'cover' }}
          />
        </div>
      </Attr>
    </div>
  )
}

const FeaturedGeos = () => {
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(null)
  const [reordering, setReordering] = useState(false)
  const [newOrder, setNewOrder] = useState([])
  const [featuredGeos, setFeaturedGeos] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_BTL_FEATURED_GEOS),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch featured geos' })

  useEffect(() => {
    dispatch(fetchBtlFeaturedGeos()).then(geos => setFeaturedGeos(geos))
  }, [])

  const cols = columns({
    editGeo: geo => {
      setEditing(geo)
    },
    deleteGeo: geo => {
      setFeaturedGeos(prev => prev.filter(g => g.id !== geo.id))
    },
  })

  const handleActionMenu = e => {
    if (e.key === 'create') {
      setCreating(true)
    } else if (e.key === 'reorder') {
      setNewOrder(featuredGeos)
      setReordering(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="create">Add geo</Menu.Item>
      <Menu.Item key="reorder">Reorder</Menu.Item>
    </Menu>
  )

  const onSaveNewOrder = () => {
    return dispatch(reorderBtlFeaturedGeos({ geoIds: newOrder.map(g => g.id) })).then(
      orderedGeos => {
        setFeaturedGeos(orderedGeos)
        setReordering(false)
      },
    )
  }

  return (
    <>
      <Head style={{ gap: '24px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Title>Featured Geos</Title>
          <Paragraph>
            Choose the cities and states that will be featured on the footer of the{' '}
            <a target="_blank" href="https://breakthelove.com/" rel="noreferrer">
              BTL homepage
            </a>
          </Paragraph>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex' }}>
          {reordering ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => setReordering(false)} style={{ marginRight: '.5em' }}>
                Cancel
              </Button>
              <Cta onClick={onSaveNewOrder}>Save</Cta>
            </div>
          ) : (
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </Head>
      {!status.fetch.success && <LoadingOutlined />}
      {reordering ? (
        <Reorderable
          items={newOrder}
          keyExtractor={g => g.id}
          renderItem={g => <FeaturedGeo key={g.id} geo={g} />}
          onChange={geos => setNewOrder(geos)}
        />
      ) : (
        <Table columns={cols} dataSource={featuredGeos} pagination={false} />
      )}
      {creating && (
        <CreateFeaturedGeoModal
          complete={geo => {
            if (geo) {
              setFeaturedGeos(prev => [...prev, geo])
            }
            setCreating(false)
          }}
        />
      )}
      {editing && (
        <EditFeaturedGeoModal
          geo={editing}
          complete={geo => {
            if (geo) {
              setFeaturedGeos(prev => prev.map(g => (geo.id === g.id ? geo : g)))
            }
            setEditing(null)
          }}
        />
      )}
    </>
  )
}

export default FeaturedGeos
