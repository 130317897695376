const OrderBadge = ({ order }) => (
  <div
    style={{
      position: 'absolute',
      top: '-12px',
      left: '-12px',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#1890ff',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      fontWeight: 'bold',
      zIndex: 1,
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    }}
  >
    {order}
  </div>
)

export default OrderBadge
