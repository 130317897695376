import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components'
import { isAdmin } from '../util/user'
import { patterns, actions as acts } from '../constants'
import { self } from '../actions/user'
import ConstsProvider from '../contexts/consts'
import Header from '../components/admin/Header'
import Dashboard from '../containers/admin/Dashboard'
import Classes from '../containers/admin/Classes'
import Class from '../containers/admin/Class'
import { CustomLandingPages } from '../containers/admin/clp'
import CustomEmailCaptures from '../containers/admin/cec'
import Cec from '../containers/admin/cec/Cec'
import InternalTags from '../containers/admin/InternalTags'
import Emails from '../containers/admin/Emails'
import Home from '../containers/admin/Home'
import Users from '../containers/admin/Users/Users'
import User from '../containers/admin/Users/User'
import NewUser from '../containers/admin/Users/NewUser'
import Pros from '../containers/admin/Pros/Pros'
import Pro from '../containers/admin/Pros/Pro'
import PromoCodes from '../containers/admin/promo-code/PromoCodes'
import PromoCode from '../containers/admin/promo-code/PromoCode'
import BTLBrandSettings from '../containers/admin/btl'
import Invoice from '../containers/admin/invoice/Invoice'
import Login from '../containers/Login'
import NotFound from '../containers/admin/404'
import Attributes from '../containers/admin/attributes'
import Permits from '../containers/admin/permit'
import Orgs from '../containers/admin/Orgs'
import Org from '../containers/admin/Orgs/Org'
import OrgVenueUpload from '../containers/admin/Orgs/venue-upload'
import Venue from '../containers/admin/Orgs/venue'
import UserSearch from '../containers/admin/search/user'
import VenueSearch from '../containers/admin/search/venue'
import OrgSearch from '../containers/admin/search/org'
import BenefitSearch from '../containers/admin/search/benefit'
import ClubSearch from '../containers/admin/search/club'
import ProgramSearch from '../containers/admin/search/program'
import Sandbox from '../containers/admin/sandbox'
import Package from '../containers/admin/packages'
import { PackageSet, PackageSets } from '../containers/admin/packages/sets'
import { UserPackage } from '../containers/admin/packages/user-package'
import Benefits from '../containers/admin/benefits'
import Benefit from '../containers/admin/benefits/benefit'
import BeneficiaryGroup from '../containers/admin/benefits/beneficiary-group'

const Background = styled.div`
  background-color: ${props => props.theme.color.background};
`
const Container = styled.div`
  padding: ${props => props.theme.spacing[4]};
`

const Admin = ({ component: Component, headless = false, ...props }) => {
  const [user, setUser] = useState(null)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    dispatch(self())
      .then(u => {
        setUser(u)
        setLoading(false)
      })
      .catch(e => {
        setError(e)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Container>Loading...</Container>
  }

  if (!isAdmin(user.type)) {
    return <NotFound />
  }

  if (headless) {
    return <Component {...props} />
  }
  return (
    <>
      <Header />
      <Container>
        <Component {...props} />
      </Container>
    </>
  )
}

const AdminRoute = ({ ...props }) => <Route {...props} component={() => <Admin {...props} />} />

const AdminApp = () => {
  return (
    <Background className="App">
      <ConstsProvider>
        <Switch>
          <Route path="/admin/login" component={Login} />
          <AdminRoute path="/admin/permits" component={Permits} />
          <AdminRoute path="/admin/programs/:id" component={Class} />
          <AdminRoute path="/admin/invoices/:id" component={Invoice} />
          <AdminRoute path="/admin/promo-codes/:id" component={PromoCode} />
          <AdminRoute path="/admin/promo-codes" component={PromoCodes} />
          <AdminRoute path="/admin/btl" component={BTLBrandSettings} />
          <AdminRoute path="/admin/dashboard" component={Dashboard} />
          <AdminRoute path="/admin/programs" component={Classes} />
          <AdminRoute path="/admin/it" component={InternalTags} />
          <AdminRoute path="/admin/clp" component={CustomLandingPages} />
          <AdminRoute exact path="/admin/cec/:id" component={Cec} />
          <AdminRoute exact path="/admin/cec" component={CustomEmailCaptures} />
          <AdminRoute path="/admin/emails" component={Emails} />
          <AdminRoute exact path="/admin/pros" component={Pros} />
          <AdminRoute exact path="/admin/attributes" component={Attributes} />
          <AdminRoute path={patterns.PRO()} component={Pro} />
          <AdminRoute
            headless
            path="/admin/s/users/:searchInput?/:forwardPath?/:extraPath?"
            component={UserSearch}
          />
          <AdminRoute
            headless
            path="/admin/s/venues/:searchInput?/:forwardPath?/:extraPath?"
            component={VenueSearch}
          />
          <AdminRoute
            headless
            path="/admin/s/orgs/:searchInput?/:forwardPath?/:extraPath?"
            component={OrgSearch}
          />
          <AdminRoute
            headless
            path="/admin/s/benefits/:searchInput?/:forwardPath?/:extraPath?"
            component={BenefitSearch}
          />
          <AdminRoute
            headless
            path="/admin/s/clubs/:searchInput?/:forwardPath?/:extraPath?"
            component={ClubSearch}
          />
          <AdminRoute
            headless
            path="/admin/s/programs/:searchInput?/:forwardPath?/:extraPath?"
            component={ProgramSearch}
          />
          <AdminRoute headless exact path="/admin/users/new" component={NewUser} />
          <AdminRoute headless exact path="/admin/sandbox" component={Sandbox} />
          <AdminRoute exact path="/admin/users" component={Users} />
          {/* this is linked in some external places like tailscale. eventually we should migrate to use /venues/:venueSig instead */}
          <AdminRoute exact path="/admin/orgs/:sig/venues/:venueSig" component={Venue} />
          <AdminRoute exact path="/admin/packages/:packageId" component={Package} />
          <AdminRoute exact path="/admin/user-packages/:userPackageId" component={UserPackage} />
          <AdminRoute exact path="/admin/package-sets/:setId" component={PackageSet} />
          <AdminRoute exact path="/admin/package-sets/" component={PackageSets} />
          <AdminRoute exact path="/admin/venues/:venueSig" component={Venue} />
          <AdminRoute exact path="/admin/orgs/:sig/venue-upload" component={OrgVenueUpload} />
          <AdminRoute exact path="/admin/orgs/:sig" component={Org} />
          <AdminRoute exact path="/admin/orgs" component={Orgs} />
          <AdminRoute
            exact
            path="/admin/benefits/:sig/beneficiary-groups/:benGroupId"
            component={BeneficiaryGroup}
          />
          <AdminRoute exact path="/admin/benefits/:sig" component={Benefit} />
          <AdminRoute exact path="/admin/benefits" component={Benefits} />
          <AdminRoute exact path={patterns.USER()} component={User} />
          <AdminRoute path="/admin" component={Home} />
        </Switch>
      </ConstsProvider>
    </Background>
  )
}

export default AdminApp
