import { Button, DatePicker, Typography } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts } from '../../../constants'
import { downloadInventoryCapacity } from '../../../actions/admin'

export function InventoryCapacity() {
  const dispatch = useDispatch()
  const [date, setDate] = useState([dayjs().subtract(3, 'month'), dayjs()])
  const status = useStatus(acts.DOWNLOAD_INVENTORY_CAPACITY)

  useStatusMsg(status, {
    error: 'Failed to download inventory capacity',
    success: 'Inventory capacity spreadsheet downloaded',
    pending: 'Downloading inventory capacity...',
  })

  const handleDownload = () => {
    if (!date[0] || !date[1]) return

    dispatch(
      downloadInventoryCapacity({
        startDate: date[0].format('YYYY-MM-DD'),
        endDate: date[1].format('YYYY-MM-DD'),
      }),
    )
  }

  const presets = [
    { label: 'Yesterday', value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
    { label: 'Last 7 Days', value: [dayjs().subtract(7, 'days'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().subtract(30, 'days'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().subtract(90, 'days'), dayjs()] },
  ]

  const disabledDate = current => {
    // Can't select days after today
    if (current && current > dayjs().endOf('day')) {
      return true
    }
    // Can't select days more than 90 days ago
    if (current && current < dayjs().subtract(90, 'days').startOf('day')) {
      return true
    }
    return false
  }

  return (
    <div>
      <Typography.Title level={3}>Analytics</Typography.Title>
      <Typography.Text>
        Choose a period to download the capacity analytics for (maximum 90 days).
      </Typography.Text>
      <div style={{ marginTop: 5 }}>
        <DatePicker.RangePicker
          value={date}
          onChange={setDate}
          presets={presets}
          disabledDate={disabledDate}
          disabled={status.pending}
        />
      </div>
      <Button disabled={!date || status.pending} onClick={handleDownload} style={{ marginTop: 20 }}>
        {status.pending && <LoadingOutlined />} Download capacity analytics
      </Button>
    </div>
  )
}
