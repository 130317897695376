import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Menu, Typography, Dropdown } from 'antd'
import { LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { actions as acts } from '../../../../constants'
import { Head, Button, Cta, Title } from '../../../../components/common'
import {
  reorderBtlFeaturedHomepageClubs,
  fetchBtlFeaturedHomepageClubs,
} from '../../../../actions/admin'

import CreateFeaturedHomepageClubModal from './CreateModal'
import EditFeaturedHomepageClubModal from './EditModal'
import ClubCard from './ClubCard'
import DraggableClubList from './DraggableClubList'
import OrderBadge from './OrderBadge'

const { Paragraph } = Typography

const FeaturedHomepageClubs = () => {
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(null)
  const [reordering, setReordering] = useState(false)
  const [newOrder, setNewOrder] = useState([])
  const [featuredHomepageClubs, setFeaturedHomepageClubs] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_BTL_FEATURED_HOMEPAGE_CLUBS),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch featured homepage clubs' })

  useEffect(() => {
    dispatch(fetchBtlFeaturedHomepageClubs()).then(featuredHomepageClubs =>
      setFeaturedHomepageClubs(featuredHomepageClubs),
    )
  }, [dispatch])

  const handleActionMenu = e => {
    if (e.key === 'create') {
      setCreating(true)
    } else if (e.key === 'reorder') {
      setNewOrder(featuredHomepageClubs)
      setReordering(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="create">Add featured club</Menu.Item>
      <Menu.Item key="reorder">Reorder</Menu.Item>
    </Menu>
  )

  const onSaveNewOrder = () => {
    return dispatch(reorderBtlFeaturedHomepageClubs({ clubIds: newOrder.map(g => g.id) })).then(
      orderedClubs => {
        setFeaturedHomepageClubs(orderedClubs)
        setReordering(false)
      },
    )
  }

  const renderClubList = clubs => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '32px',
        position: 'relative',
        justifyContent: 'flex-start',
      }}
    >
      {clubs.map((club, index) => (
        <div
          key={club.id}
          style={{
            width: '320px',
            position: 'relative',
          }}
        >
          <OrderBadge order={index + 1} />
          <ClubCard
            featuredHomepageClub={club}
            onEdit={() => setEditing(club)}
            onDelete={featuredHomepageClub => {
              setFeaturedHomepageClubs(prev => prev.filter(g => g.id !== featuredHomepageClub.id))
            }}
          />
        </div>
      ))}
    </div>
  )

  return (
    <>
      <Head style={{ gap: '24px' }}>
        <div>
          <Title>Featured homepage clubs</Title>
          <Paragraph>
            Manage the clubs that will be featured on the{' '}
            <a target="_blank" href="https://breakthelove.com/" rel="noreferrer">
              BTL homepage.
            </a>{' '}
            This clubs are going to be used to populate the options on the search bar (ordered
            alphabetically) and the featured clubs carousel section (ordered by the order set
            bellow).
          </Paragraph>
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex' }}>
          {reordering ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={() => setReordering(false)} style={{ marginRight: '.5em' }}>
                Cancel
              </Button>
              <Cta onClick={onSaveNewOrder}>Save</Cta>
            </div>
          ) : (
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Button style={{ minWidth: '100px' }}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </Head>
      {!status.fetch.success && <LoadingOutlined />}
      {reordering ? (
        <DraggableClubList items={newOrder} onReorder={setNewOrder} />
      ) : (
        renderClubList(featuredHomepageClubs)
      )}
      {creating && (
        <CreateFeaturedHomepageClubModal
          complete={featuredHomepageClub => {
            if (featuredHomepageClub) {
              setFeaturedHomepageClubs(prev => [...prev, featuredHomepageClub])
            }
            setCreating(false)
          }}
        />
      )}
      {editing && (
        <EditFeaturedHomepageClubModal
          initialFeaturedHomepageClub={editing}
          complete={featuredHomepageClub => {
            if (!featuredHomepageClub) {
              setEditing(null)
              return
            }

            const newFeaturedHomepageClubs = featuredHomepageClubs.map(fhc =>
              featuredHomepageClub.id === fhc.id ? featuredHomepageClub : fhc,
            )

            setFeaturedHomepageClubs(newFeaturedHomepageClubs)
            setEditing(null)
          }}
        />
      )}
    </>
  )
}

export default FeaturedHomepageClubs
