import qs from 'query-string'
import { urls, actions as acts } from '../constants'
import fetchData, { fetchCsv, fetchBlob } from '../util/fetch'
import act from './act'

export const fetchProgram = id => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_PENDING, payload: { id } })
  try {
    const program = await fetchData({ url: urls.admin.PROGRAM(id) })
    dispatch({ type: acts.FETCH_PROGRAM_SUCCESS, payload: program })
    return program
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_FAILURE, payload: err })
    throw err
  }
}

export const fetchPrograms =
  ({ limit = 10, offset = 0 }) =>
  async dispatch => {
    dispatch({ type: acts.FETCH_PROGRAMS_PENDING })
    try {
      const payload = await fetchData({
        url: qs.stringifyUrl({
          url: urls.admin.PROGRAMS(),
          query: {
            limit,
            offset,
          },
        }),
      })
      dispatch({ type: acts.FETCH_PROGRAMS_SUCCESS, payload })
      return payload
    } catch (err) {
      console.log(err)
      dispatch({ type: acts.FETCH_PROGRAMS_FAILURE, payload: err })
      throw err
    }
  }

export const updateProgram = (programId, attrs) => async dispatch => {
  dispatch({
    type: acts.UPDATE_ADMIN_PROGRAM_PENDING,
    payload: { programId, attrs },
  })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.admin.PROGRAM(programId),
      body: { ...attrs },
    })
    dispatch({ type: acts.UPDATE_ADMIN_PROGRAM_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.UPDATE_ADMIN_PROGRAM_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const cancelProgram = act(acts.CANCEL_PROGRAM, ({ programId, note, sendEmails }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.CANCEL_PROGRAM(programId),
    body: { note, sendEmails },
  }),
)

export const userCancelResReservationBookings = act(
  acts.USER_ADMIN_CANCEL_RES_RESERVATION_BOOKINGS,
  ({ userId, bookings, resReservations }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.USER_ADMIN_CANCEL_RES_RESERVATION_BOOKINGS(userId),
      body: { bookings, resReservations },
    }),
)

export const userCancelProgramBookings = act(
  acts.USER_ADMIN_CANCEL_PROGRAM_BOOKINGS,
  ({ userId, bookings }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.USER_ADMIN_CANCEL_PROGRAM_BOOKINGS(userId),
      body: { bookings },
    }),
)

export const fetchCustomLandingPages = () => async dispatch => {
  dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGES_PENDING })
  try {
    const res = await fetchData({ url: urls.admin.CUSTOM_LANDING_PAGES() })
    dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGES_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGES_FAILURE, payload: err })
    console.log(err)
  }
}

export const fetchCustomLandingPage = id => async dispatch => {
  dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGE_PENDING, payload: { id } })
  try {
    const res = await fetchData({ url: urls.admin.CUSTOM_LANDING_PAGE(id) })
    dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGE_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.FETCH_CUSTOM_LANDING_PAGE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const createCustomLandingPage =
  ({ name, description, internalTagId, urlSlug, redirectUrl }) =>
  async dispatch => {
    dispatch({
      type: acts.CREATE_CUSTOM_LANDING_PAGE_PENDING,
      payload: { name, description, internalTagId, urlSlug },
    })
    try {
      const res = await fetchData({
        type: 'POST',
        url: urls.admin.CUSTOM_LANDING_PAGES(),
        body: {
          name,
          description,
          internalTagId,
          urlSlug,
          redirectUrl,
        },
      })
      dispatch({ type: acts.CREATE_CUSTOM_LANDING_PAGE_SUCCESS, payload: res })
      return res
    } catch (err) {
      dispatch({ type: acts.CREATE_CUSTOM_LANDING_PAGE_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const updateCustomLandingPage =
  ({ id, name, description, internalTagId, urlSlug, redirectUrl }) =>
  async dispatch => {
    dispatch({
      type: acts.UPDATE_CUSTOM_LANDING_PAGE_PENDING,
      payload: { id, name, description, internalTagId, urlSlug },
    })
    try {
      const res = await fetchData({
        type: 'PATCH',
        url: urls.admin.CUSTOM_LANDING_PAGE(id),
        body: {
          name,
          description,
          internalTagId,
          urlSlug,
          redirectUrl,
        },
      })
      dispatch({ type: acts.UPDATE_CUSTOM_LANDING_PAGE_SUCCESS, payload: res })
      return res
    } catch (err) {
      dispatch({ type: acts.UPDATE_CUSTOM_LANDING_PAGE_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const deleteCustomLandingPage = id => async dispatch => {
  dispatch({ type: acts.DELETE_CUSTOM_LANDING_PAGE_PENDING, payload: { id } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.CUSTOM_LANDING_PAGE(id),
    })
    dispatch({ type: acts.DELETE_CUSTOM_LANDING_PAGE_SUCCESS, payload: { id } })
  } catch (err) {
    dispatch({ type: acts.DELETE_CUSTOM_LANDING_PAGE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchInternalTags = () => async dispatch => {
  dispatch({ type: acts.FETCH_INTERNAL_TAGS_PENDING, payload: {} })
  try {
    const res = await fetchData({ url: urls.admin.INTERNAL_TAGS() })
    dispatch({ type: acts.FETCH_INTERNAL_TAGS_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.FETCH_INTERNAL_TAGS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const createInternalTag = (name, description) => async dispatch => {
  dispatch({ type: acts.CREATE_INTERNAL_TAG_PENDING, payload: { name, description } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.admin.INTERNAL_TAGS(),
      body: { name, description },
    })
    dispatch({ type: acts.CREATE_INTERNAL_TAG_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.CREATE_INTERNAL_TAG_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const deleteInternalTag = id => async dispatch => {
  dispatch({ type: acts.DELETE_INTERNAL_TAG_PENDING, payload: { id } })
  try {
    await fetchData({ type: 'DELETE', url: urls.admin.INTERNAL_TAG(id) })
    dispatch({ type: acts.DELETE_INTERNAL_TAG_SUCCESS, payload: { id } })
  } catch (err) {
    dispatch({ type: acts.DELETE_INTERNAL_TAG_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchAttributes = () => async dispatch => {
  dispatch({ type: acts.FETCH_ATTRIBUTES_PENDING, payload: {} })
  try {
    const attrs = await fetchData({ url: urls.admin.ATTRIBUTES() })
    dispatch({ type: acts.FETCH_ATTRIBUTES_SUCCESS, payload: attrs })
  } catch (err) {
    dispatch({ type: acts.FETCH_ATTRIBUTES_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const createAttribute = (type, attributeDetails, values) => async dispatch => {
  const { name, settings } = attributeDetails
  dispatch({ type: acts.CREATE_ATTRIBUTE_PENDING, payload: { type, name, settings, values } })
  try {
    const attr = await fetchData({
      type: 'POST',
      url: urls.admin.ATTRIBUTES(),
      body: { type, name, settings, values },
    })
    dispatch({ type: acts.CREATE_ATTRIBUTE_SUCCESS, payload: attr })
  } catch (err) {
    dispatch({ type: acts.CREATE_ATTRIBUTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updateAttribute = (id, attributeDetails, values) => async dispatch => {
  const { name, settings } = attributeDetails
  dispatch({ type: acts.UPDATE_ATTRIBUTE_PENDING, payload: { id, name, settings, values } })
  try {
    const newAttr = await fetchData({
      type: 'PATCH',
      url: urls.admin.ATTRIBUTE(id),
      body: { name, settings, values },
    })
    dispatch({ type: acts.UPDATE_ATTRIBUTE_SUCCESS, payload: newAttr })
  } catch (err) {
    dispatch({ type: acts.UPDATE_ATTRIBUTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const deleteAttribute = id => async dispatch => {
  dispatch({ type: acts.DELETE_ATTRIBUTE_PENDING, payload: { id } })
  try {
    await fetchData({ type: 'DELETE', url: urls.admin.ATTRIBUTE(id) })
    dispatch({ type: acts.DELETE_ATTRIBUTE_SUCCESS, payload: { id } })
  } catch (err) {
    dispatch({ type: acts.DELETE_ATTRIBUTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchProgramsSearch = act(
  acts.FETCH_PROGRAMS_SEARCH,
  ({ sort = {}, searchInput = '', limit = null, offset = 0 }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.PROGRAMS_SEARCH(),
        query: {
          sortColumn: sort.column,
          sortOrder: sort.order,
          searchInput,
          limit,
          offset,
        },
      }),
    }),
)

export const fetchUpcomingPrograms =
  ({ limit = 0 } = {}) =>
  async dispatch => {
    dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_PENDING, payload: { limit } })
    try {
      let url = urls.admin.BOOKABLE_PROGRAMS()
      if (limit) {
        url = qs.stringifyUrl({ url, query: { limit } })
      }
      const programs = await fetchData({ url })
      dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_SUCCESS, payload: programs })
      return programs
    } catch (err) {
      dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const getRegisteredEmails = () => async dispatch => {
  dispatch({ type: acts.GET_REGISTERED_EMAILS_PENDING, payload: {} })
  try {
    const res = await fetchData({ url: '/admin/emails' })
    dispatch({ type: acts.GET_REGISTERED_EMAILS_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.GET_REGISTERED_EMAILS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const generateEmailPreview = (id, state) => async dispatch => {
  dispatch({ type: acts.GENERATE_EMAIL_PREVIEW_PENDING, payload: { id, state } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: `/admin/emails/${id}/preview`,
      body: state,
    })
    dispatch({ type: acts.GENERATE_EMAIL_PREVIEW_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.GENERATE_EMAIL_PREVIEW_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const sendEmail = (id, state) => async dispatch => {
  dispatch({ type: acts.SEND_EMAIL_PENDING, payload: { id, state } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: `/admin/emails/${id}/send`,
      body: state,
    })
    dispatch({ type: acts.SEND_EMAIL_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.SEND_EMAIL_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUsers = act(
  acts.FETCH_USERS,
  ({ sort = {}, searchInput = '', limit = null, offset = 0, equinox = false }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.USERS(),
        query: {
          column: sort.column,
          order: sort.order,
          searchInput,
          limit,
          offset,
          equinox,
        },
      }),
    }),
)

export const updateUserRole = (id, type) => async dispatch => {
  dispatch({ type: acts.UPDATE_USER_TYPE_PENDING, payload: { id, type } })
  try {
    const res = await fetchData({
      type: 'PATCH',
      url: urls.admin.USER_TYPE(id),
      body: { type },
    })
    dispatch({ type: acts.UPDATE_USER_TYPE_SUCCESS, payload: res })
    return res
  } catch (err) {
    dispatch({ type: acts.UPDATE_USER_TYPE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchProPage = id => async dispatch => {
  dispatch({ type: acts.FETCH_PRO_PAGE_PENDING, payload: { id } })
  try {
    const page = await fetchData({ url: urls.admin.PRO_PAGE(id) })
    dispatch({ type: acts.FETCH_PRO_PAGE_SUCCESS, payload: page })
    return page
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PRO_PAGE_FAILURE, payload: err })
    throw err
  }
}

// fetches a pro shop using the pro page's id
export const fetchProShop = pageId => async dispatch => {
  dispatch({ type: acts.FETCH_PRO_SHOP_PENDING, payload: { pageId } })
  try {
    const shop = await fetchData({ url: urls.admin.PRO_SHOP(pageId) })
    dispatch({ type: acts.FETCH_PRO_SHOP_SUCCESS, payload: shop })
    return shop
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PRO_SHOP_FAILURE, payload: err })
    throw err
  }
}

export const createProShop = (pageId, name, description) => async dispatch => {
  dispatch({ type: acts.CREATE_PRO_SHOP_PENDING, payload: { pageId, name, description } })
  try {
    const shop = await fetchData({
      type: 'POST',
      url: urls.admin.PRO_SHOP(pageId),
      body: { name, description },
    })
    dispatch({ type: acts.CREATE_PRO_SHOP_SUCCESS, payload: shop })
    return shop
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PRO_SHOP_FAILURE, payload: err })
    throw err
  }
}

export const updateProShop = (pageId, name, description) => async dispatch => {
  dispatch({ type: acts.UPDATE_PRO_SHOP_PENDING, payload: { pageId, name, description } })
  try {
    const shop = await fetchData({
      type: 'PATCH',
      url: urls.admin.PRO_SHOP(pageId),
      body: { name, description },
    })
    dispatch({ type: acts.UPDATE_PRO_SHOP_SUCCESS, payload: shop })
    return shop
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_PRO_SHOP_FAILURE, payload: err })
    throw err
  }
}

export const deleteProShop = pageId => async dispatch => {
  dispatch({ type: acts.DELETE_PRO_SHOP_PENDING, payload: { pageId } })
  try {
    await fetchData({ type: 'DELETE', url: urls.admin.PRO_SHOP(pageId) })
    dispatch({ type: acts.DELETE_PRO_SHOP_SUCCESS, payload: { pageId } })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PRO_SHOP_FAILURE, payload: err })
    throw err
  }
}

export const addProShopItem = (pageId, name, description, link) => async dispatch => {
  dispatch({ type: acts.ADD_PRO_SHOP_ITEM_PENDING, payload: { pageId, name, description, link } })
  try {
    const item = await fetchData({
      type: 'POST',
      url: urls.admin.PRO_SHOP_ITEMS(pageId),
      body: { name, description, link },
    })
    dispatch({ type: acts.ADD_PRO_SHOP_ITEM_SUCCESS, payload: item })
    return item
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.ADD_PRO_SHOP_ITEM_FAILURE, payload: err })
    throw err
  }
}

export const deleteProShopItem = (pageId, itemId) => async dispatch => {
  dispatch({ type: acts.DELETE_PRO_SHOP_ITEM_PENDING, payload: { pageId, itemId } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.PRO_SHOP_ITEM(pageId, itemId),
    })
    dispatch({ type: acts.DELETE_PRO_SHOP_ITEM_SUCCESS, payload: { pageId, itemId } })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PRO_SHOP_ITEM_FAILURE, payload: err })
    throw err
  }
}

export const updateProShopItem = (pageId, itemId, name, description, link) => async dispatch => {
  dispatch({
    type: acts.UPDATE_PRO_SHOP_ITEM_PENDING,
    payload: { pageId, itemId, name, description, link },
  })
  try {
    const item = await fetchData({
      type: 'PATCH',
      url: urls.admin.PRO_SHOP_ITEM(pageId, itemId),
      body: { name, description, link },
    })
    dispatch({ type: acts.UPDATE_PRO_SHOP_ITEM_SUCCESS, payload: item })
    return item
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_PRO_SHOP_ITEM_FAILURE, payload: err })
    throw err
  }
}

export const addProShopItemImages = (pageId, itemId, images) => async dispatch => {
  dispatch({ type: acts.ADD_PRO_SHOP_ITEM_IMAGES_PENDING, payload: { pageId, itemId, images } })
  const fd = new FormData()
  images.forEach(i => fd.append('image', i))
  try {
    const images = await fetchData({
      type: 'POST',
      url: urls.admin.PRO_SHOP_ITEM_IMAGES(pageId, itemId),
      body: fd,
      isUpload: true,
    })
    dispatch({ type: acts.ADD_PRO_SHOP_ITEM_IMAGES_SUCCESS, payload: images })
    return images
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.ADD_PRO_SHOP_ITEM_IMAGES_FAILURE, payload: err })
    throw err
  }
}

export const deleteProShopItemImage = (pageId, itemId, imageId) => async dispatch => {
  dispatch({ type: acts.DELETE_PRO_SHOP_ITEM_IMAGE_PENDING, payload: { pageId, itemId, imageId } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.PRO_SHOP_ITEM_IMAGE(pageId, itemId, imageId),
    })
    dispatch({
      type: acts.DELETE_PRO_SHOP_ITEM_IMAGE_SUCCESS,
      payload: { pageId, itemId, imageId },
    })
    return { pageId, itemId, imageId }
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PRO_SHOP_ITEM_IMAGE_FAILURE, payload: err })
    throw err
  }
}

export const addProPageImages = (id, images) => async dispatch => {
  dispatch({ type: acts.ADD_PRO_PAGE_IMAGES_PENDING, payload: { id, images } })
  const fd = new FormData()
  images.forEach(i => fd.append('image', i))
  try {
    const images = await fetchData({
      type: 'POST',
      url: urls.admin.PRO_PAGE_IMAGES(id),
      body: fd,
      isUpload: true,
    })
    dispatch({ type: acts.ADD_PRO_PAGE_IMAGES_SUCCESS, payload: images })
    return images
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.ADD_PRO_PAGE_IMAGES_FAILURE, payload: err })
    throw err
  }
}

export const deleteProPageImage = (id, imageId) => async dispatch => {
  dispatch({ type: acts.DELETE_PRO_PAGE_IMAGE_PENDING, payload: { id, imageId } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.PRO_PAGE_IMAGE(id, imageId),
    })
    dispatch({ type: acts.DELETE_PRO_PAGE_IMAGE_SUCCESS, payload: { id, imageId } })
    return { id, imageId }
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PRO_PAGE_IMAGE_FAILURE, payload: err })
    throw err
  }
}

export const createProPage = page => async dispatch => {
  dispatch({ type: acts.CREATE_PRO_PAGE_PENDING, payload: page })
  try {
    const newPage = await fetchData({
      type: 'POST',
      url: urls.admin.PRO_PAGES(),
      body: page,
    })
    dispatch({ type: acts.CREATE_PRO_PAGE_SUCCESS, payload: newPage })
    return newPage
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PRO_PAGE_FAILURE, payload: err })
    throw err
  }
}

export const updateProPage = (page, id) => async dispatch => {
  dispatch({ type: acts.UPDATE_PRO_PAGE_PENDING, payload: { id, page } })
  try {
    const newPage = await fetchData({
      type: 'PATCH',
      url: urls.admin.PRO_PAGE(id),
      body: page,
    })
    dispatch({ type: acts.UPDATE_PRO_PAGE_SUCCESS, payload: newPage })
    return newPage
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_PRO_PAGE_FAILURE, payload: err })
    throw err
  }
}

export const deleteProPage = pageId => async dispatch => {
  dispatch({ type: acts.DELETE_PRO_PAGE_PENDING, payload: { pageId } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.PRO_PAGE(pageId),
    })
    dispatch({ type: acts.DELETE_PRO_PAGE_SUCCESS, payload: {} })
  } catch (err) {
    dispatch({ type: acts.DELETE_PRO_PAGE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUser = id => async dispatch => {
  dispatch({ type: acts.FETCH_USER_PENDING, payload: { id } })
  try {
    const user = await fetchData({ url: urls.admin.USER(id) })
    dispatch({ type: acts.FETCH_USER_SUCCESS, payload: user })
    return user
  } catch (err) {
    dispatch({ type: acts.FETCH_USER_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUserProgramBookings = id => async dispatch => {
  dispatch({ type: acts.FETCH_USER_PROGRAM_BOOKINGS_PENDING, payload: { id } })
  try {
    const bookings = await fetchData({ url: urls.admin.USER_PROGRAM_BOOKINGS(id) })
    dispatch({ type: acts.FETCH_USER_PROGRAM_BOOKINGS_SUCCESS, payload: bookings })
    return bookings
  } catch (err) {
    dispatch({ type: acts.FETCH_USER_PROGRAM_BOOKINGS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUserPackages = act(acts.FETCH_USER_PACKAGES, ({ userId }) =>
  fetchData({ url: urls.admin.USER_PACKAGES(userId) }),
)

export const fetchUserWaitlists = act(acts.FETCH_USER_WAITLISTS, ({ userId }) =>
  fetchData({ url: urls.admin.USER_WAITLISTS(userId) }),
)

export const fetchUserOrgTeamMemberships = act(acts.FETCH_USER_ORG_TEAM_MEMBERSHIPS, ({ userId }) =>
  fetchData({ url: urls.admin.USER_ORG_TEAM_MEMBERSHIPS(userId) }),
)

export const removeUserOrgTeamMembership = act(
  acts.REMOVE_USER_ORG_TEAM_MEMBERSHIP,
  ({ userId, membershipId }) =>
    fetchData({ type: 'DELETE', url: urls.admin.USER_ORG_TEAM_MEMBERSHIP(userId, membershipId) }),
)

export const addUserOrgTeamMembership = act(
  acts.ADD_USER_ORG_TEAM_MEMBERSHIP,
  ({ userId, orgId }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.USER_ORG_TEAM_MEMBERSHIPS(userId),
      body: { orgId },
    }),
)

export const fetchUserVenueTeamMemberships = act(
  acts.FETCH_USER_VENUE_TEAM_MEMBERSHIPS,
  ({ userId }) => fetchData({ url: urls.admin.USER_VENUE_TEAM_MEMBERSHIPS(userId) }),
)

export const addUserVenueTeamMembership = act(
  acts.ADD_USER_VENUE_TEAM_MEMBERSHIP,
  ({ userId, venueId }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.USER_VENUE_TEAM_MEMBERSHIPS(userId),
      body: { venueId },
    }),
)

export const removeUserVenueTeamMembership = act(
  acts.REMOVE_USER_VENUE_TEAM_MEMBERSHIP,
  ({ userId, membershipId }) =>
    fetchData({ type: 'DELETE', url: urls.admin.USER_VENUE_TEAM_MEMBERSHIP(userId, membershipId) }),
)

export const fetchUserBeneficiaries = act(acts.FETCH_USER_BENEFICIARIES, ({ userId }) =>
  fetchData({ url: urls.admin.USER_BENEFICIARIES(userId) }),
)

export const fetchUserCustomers = act(acts.FETCH_USER_CUSTOMERS, ({ userId }) =>
  fetchData({ url: urls.admin.CUSTOMER_ORGS(userId) }),
)

export const fetchAllPromoCodes = () => async dispatch => {
  dispatch({ type: acts.FETCH_ALL_PROMO_CODES_PENDING, payload: {} })
  try {
    const codes = await fetchData({ url: urls.admin.PROMO_CODES() })
    dispatch({ type: acts.FETCH_ALL_PROMO_CODES_SUCCESS, payload: codes })
    return codes
  } catch (err) {
    dispatch({ type: acts.FETCH_ALL_PROMO_CODES_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchPromoCode = id => async dispatch => {
  dispatch({ type: acts.FETCH_PROMO_CODE_PENDING, payload: { id } })
  try {
    const code = await fetchData({ url: urls.admin.PROMO_CODE(id) })
    dispatch({ type: acts.FETCH_PROMO_CODE_SUCCESS, payload: code })
    return code
  } catch (err) {
    dispatch({ type: acts.FETCH_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const createPromoCode = attrs => async dispatch => {
  dispatch({ type: acts.CREATE_PROMO_CODE_PENDING, payload: { ...attrs } })
  try {
    const code = await fetchData({
      type: 'POST',
      url: urls.admin.PROMO_CODES(),
      body: attrs,
    })
    dispatch({ type: acts.CREATE_PROMO_CODE_SUCCESS, payload: code })
    return code
  } catch (err) {
    dispatch({ type: acts.CREATE_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updatePromoCode = (id, attrs) => async dispatch => {
  dispatch({ type: acts.UPDATE_PROMO_CODE_PENDING, payload: { id, ...attrs } })
  try {
    const updatedCode = await fetchData({
      type: 'PATCH',
      url: urls.admin.PROMO_CODE(id),
      body: attrs,
    })
    dispatch({ type: acts.UPDATE_PROMO_CODE_SUCCESS, payload: updatedCode })
    return updatedCode
  } catch (err) {
    dispatch({ type: acts.UPDATE_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const activatePromoCode = id => async dispatch => {
  dispatch({ type: acts.ACTIVATE_PROMO_CODE, payload: { id } })
  try {
    const updatedCode = await dispatch(updatePromoCode(id, { active: true }))
    dispatch({ type: acts.ACTIVATE_PROMO_CODE_SUCCESS, payload: updatedCode })
    return updatedCode
  } catch (err) {
    dispatch({ type: acts.ACTIVATE_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const deactivatePromoCode = id => async dispatch => {
  dispatch({ type: acts.DEACTIVATE_PROMO_CODE, payload: { id } })
  try {
    const updatedCode = await dispatch(updatePromoCode(id, { active: false }))
    dispatch({ type: acts.DEACTIVATE_PROMO_CODE_SUCCESS, payload: updatedCode })
    return updatedCode
  } catch (err) {
    dispatch({ type: acts.DEACTIVATE_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const deletePromoCode = id => async dispatch => {
  dispatch({ type: acts.DELETE_PROMO_CODE_PENDING, payload: { id } })
  try {
    await fetchData({
      type: 'DELETE',
      url: urls.admin.PROMO_CODE(id),
    })
    dispatch({ type: acts.DELETE_PROMO_CODE_SUCCESS, payload: { id } })
    return id
  } catch (err) {
    dispatch({ type: acts.DELETE_PROMO_CODE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updateUser = act(acts.UPDATE_USER, ({ id, attrs, image }) => {
  let body = attrs
  const hasImages = !!image
  if (hasImages) {
    const fd = new FormData()
    fd.append('image', image)
    fd.append('attrs', JSON.stringify(body))
    body = fd
  }
  return fetchData({
    type: 'PATCH',
    url: urls.admin.USER(id),
    isUpload: hasImages,
    body,
  })
})

export const updateUserLevel =
  ({ userId, level }) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_USER_LEVEL_PENDING, payload: { id: userId, level } })
    try {
      const user = await fetchData({
        type: 'PATCH',
        url: urls.admin.USER_LEVEL(userId),
        body: { playerLevel: level },
      })
      dispatch({ type: acts.UPDATE_USER_LEVEL_SUCCESS, payload: user })
      return user
    } catch (err) {
      dispatch({ type: acts.UPDATE_USER_LEVEL_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const updateUserProfileImage =
  ({ userId, image }) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_USER_PROFILE_IMAGE_PENDING, payload: { id: userId, image } })
    const fd = new FormData()
    fd.append('image', image)
    try {
      const user = await fetchData({
        type: 'PATCH',
        url: urls.admin.USER_PROFILE_IMAGE(userId),
        body: fd,
        isUpload: true,
      })
      dispatch({ type: acts.UPDATE_USER_PROFILE_IMAGE_SUCCESS, payload: user })
      return user
    } catch (err) {
      dispatch({ type: acts.UPDATE_USER_PROFILE_IMAGE_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const fetchInvoice = id => async dispatch => {
  dispatch({ type: acts.FETCH_INVOICE_PENDING, payload: { id } })
  try {
    const invoice = await fetchData({ url: urls.admin.INVOICE(id) })
    dispatch({ type: acts.FETCH_INVOICE_SUCCESS, payload: invoice })
    return invoice
  } catch (err) {
    dispatch({ type: acts.FETCH_INVOICE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updateInvoiceNote = (id, note) => async dispatch => {
  dispatch({ type: acts.UPDATE_INVOICE_NOTE_PENDING, payload: { id, note } })
  try {
    await fetchData({
      type: 'PATCH',
      url: urls.admin.INVOICE_NOTE(id),
      body: { note },
    })
    dispatch({ type: acts.UPDATE_INVOICE_NOTE_SUCCESS, payload: { id, note } })
    return note
  } catch (err) {
    dispatch({ type: acts.UPDATE_INVOICE_NOTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updatePaymentNote = (id, note) => async dispatch => {
  dispatch({ type: acts.UPDATE_PAYMENT_NOTE_PENDING, payload: { id, note } })
  try {
    await fetchData({
      type: 'PATCH',
      url: urls.admin.PAYMENT_NOTE(id),
      body: { note },
    })
    dispatch({ type: acts.UPDATE_PAYMENT_NOTE_SUCCESS, payload: { id, note } })
    return note
  } catch (err) {
    dispatch({ type: acts.UPDATE_PAYMENT_NOTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updateLineItemNote = (id, note) => async dispatch => {
  dispatch({ type: acts.UPDATE_LINE_ITEM_NOTE_PENDING, payload: { id, note } })
  try {
    await fetchData({
      type: 'PATCH',
      url: urls.admin.LINE_ITEM_NOTE(id),
      body: { note },
    })
    dispatch({ type: acts.UPDATE_LINE_ITEM_NOTE_SUCCESS, payload: { id, note } })
    return note
  } catch (err) {
    dispatch({ type: acts.UPDATE_LINE_ITEM_NOTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const refundPayment = (paymentId, lineItemIds, reason) => async dispatch => {
  dispatch({ type: acts.REFUND_PAYMENT_PENDING, payload: { paymentId, lineItemIds, reason } })
  try {
    const refund = await fetchData({
      type: 'POST',
      url: urls.admin.REFUND_PAYMENT(paymentId),
      body: { lineItemIds, reason },
    })
    dispatch({ type: acts.REFUND_PAYMENT_SUCCESS, payload: refund })
    return refund
  } catch (err) {
    dispatch({ type: acts.REFUND_PAYMENT_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUserClubMemberships = userId => async dispatch => {
  dispatch({ type: acts.FETCH_USER_CLUB_MEMBERSHIPS_PENDING, payload: { userId } })
  try {
    const memberships = await fetchData({ url: urls.admin.USER_CLUB_MEMBERSHIPS(userId) })
    dispatch({ type: acts.FETCH_USER_CLUB_MEMBERSHIPS_SUCCESS, payload: memberships })
    return memberships
  } catch (err) {
    dispatch({ type: acts.FETCH_USER_CLUB_MEMBERSHIPS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchNycPermitAccounts = () => async dispatch => {
  dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNTS_PENDING, payload: {} })
  try {
    const accounts = await fetchData({ url: urls.admin.NYC_PERMIT_ACCOUNTS() })
    dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNTS_SUCCESS, payload: accounts })
    return accounts
  } catch (err) {
    dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNTS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchNycPermitAccount = id => async dispatch => {
  dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNT_PENDING, payload: { id } })
  try {
    const account = await fetchData({ url: urls.admin.NYC_PERMIT_ACCOUNT(id) })
    dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNT_SUCCESS, payload: account })
    return account
  } catch (err) {
    dispatch({ type: acts.FETCH_NYC_PERMIT_ACCOUNT_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchNycPermitCards = () => async dispatch => {
  dispatch({ type: acts.FETCH_NYC_PERMIT_CARDS_PENDING, payload: {} })
  try {
    const cards = await fetchData({ url: urls.admin.NYC_PERMIT_CARDS() })
    dispatch({ type: acts.FETCH_NYC_PERMIT_CARDS_SUCCESS, payload: cards })
    return cards
  } catch (err) {
    dispatch({ type: acts.FETCH_NYC_PERMIT_CARDS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchNycPermitCard = id => async dispatch => {
  dispatch({ type: acts.FETCH_NYC_PERMIT_CARD_PENDING, payload: { id } })
  try {
    const card = await fetchData({ url: urls.admin.NYC_PERMIT_CARD(id) })
    dispatch({ type: acts.FETCH_NYC_PERMIT_CARD_SUCCESS, payload: card })
    return card
  } catch (err) {
    dispatch({ type: acts.FETCH_NYC_PERMIT_CARD_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const createNycPermitAccount =
  ({ email, password, notes }) =>
  async dispatch => {
    dispatch({ type: acts.CREATE_NYC_PERMIT_ACCOUNT_PENDING, payload: { email, password, notes } })
    try {
      const account = await fetchData({
        type: 'POST',
        url: urls.admin.NYC_PERMIT_ACCOUNTS(),
        body: {
          email,
          password,
          notes,
        },
      })
      dispatch({ type: acts.CREATE_NYC_PERMIT_ACCOUNT_SUCCESS, payload: account })
      return account
    } catch (err) {
      dispatch({ type: acts.CREATE_NYC_PERMIT_ACCOUNT_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const createNycPermitCard =
  ({ cardNumber, cardExpMonth, cardExpYear, cardCvv, notes }) =>
  async dispatch => {
    dispatch({
      type: acts.CREATE_NYC_PERMIT_CARD_PENDING,
      payload: {
        cardNumber,
        cardExpMonth,
        cardExpYear,
        cardCvv,
        notes,
      },
    })
    try {
      const card = await fetchData({
        type: 'POST',
        url: urls.admin.NYC_PERMIT_CARDS(),
        body: {
          cardNumber,
          cardExpMonth,
          cardExpYear,
          cardCvv,
          notes,
        },
      })
      dispatch({ type: acts.CREATE_NYC_PERMIT_CARD_SUCCESS, payload: card })
      return card
    } catch (err) {
      dispatch({ type: acts.CREATE_NYC_PERMIT_CARD_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const updateNycPermitAccount =
  (id, { notes, active }) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_NYC_PERMIT_ACCOUNT_PENDING, payload: { id, notes, active } })
    try {
      const updatedAccount = await fetchData({
        type: 'PATCH',
        url: urls.admin.NYC_PERMIT_ACCOUNT(id),
        body: { notes, active },
      })
      dispatch({ type: acts.UPDATE_NYC_PERMIT_ACCOUNT_SUCCESS, payload: updatedAccount })
      return updatedAccount
    } catch (err) {
      dispatch({ type: acts.UPDATE_NYC_PERMIT_ACCOUNT_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const updateNycPermitCard =
  (id, { notes, active }) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_NYC_PERMIT_CARD_PENDING, payload: { id, notes, active } })
    try {
      const updatedCard = await fetchData({
        type: 'PATCH',
        url: urls.admin.NYC_PERMIT_CARD(id),
        body: { notes, active },
      })
      dispatch({ type: acts.UPDATE_NYC_PERMIT_CARD_SUCCESS, payload: updatedCard })
      return updatedCard
    } catch (err) {
      dispatch({ type: acts.UPDATE_NYC_PERMIT_CARD_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const fetchPurchasablePermits = () => async dispatch => {
  dispatch({ type: acts.FETCH_PURCHASABLE_PERMITS_PENDING, payload: {} })
  try {
    const permits = await fetchData({ url: urls.admin.PURCHASABLE_PERMITS() })
    dispatch({ type: acts.FETCH_PURCHASABLE_PERMITS_SUCCESS, payload: permits })
    return permits
  } catch (err) {
    dispatch({ type: acts.FETCH_PURCHASABLE_PERMITS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const updatePurchasablePermit =
  (id, { status }) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_PURCHASABLE_PERMIT_PENDING, payload: { id, status } })
    try {
      const updatedPermit = await fetchData({
        type: 'PATCH',
        url: urls.admin.PURCHASABLE_PERMIT(id),
        body: { status },
      })
      dispatch({ type: acts.UPDATE_PURCHASABLE_PERMIT_SUCCESS, payload: updatedPermit })
      return updatedPermit
    } catch (err) {
      dispatch({ type: acts.UPDATE_PURCHASABLE_PERMIT_FAILURE, payload: err })
      console.log(err)
      throw err
    }
  }

export const fetchAllUserPermits = () => async dispatch => {
  dispatch({ type: acts.FETCH_ALL_USER_PERMITS_PENDING, payload: {} })
  try {
    const permits = await fetchData({ url: urls.admin.USER_PERMITS() })
    dispatch({ type: acts.FETCH_ALL_USER_PERMITS_SUCCESS, payload: permits })
    return permits
  } catch (err) {
    dispatch({ type: acts.FETCH_ALL_USER_PERMITS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchUserPermit = id => async dispatch => {
  dispatch({ type: acts.FETCH_USER_PERMIT_PENDING, payload: { id } })
  try {
    const permit = await fetchData({ url: urls.admin.USER_PERMIT(id) })
    dispatch({ type: acts.FETCH_USER_PERMIT_SUCCESS, payload: permit })
    return permit
  } catch (err) {
    dispatch({ type: acts.FETCH_USER_PERMIT_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchPermitsForUser = userId => async dispatch => {
  dispatch({ type: acts.FETCH_USER_USER_PERMITS_PENDING, payload: { userId } })
  try {
    const permits = await fetchData({ url: urls.admin.USER_USER_PERMITS(userId) })
    dispatch({ type: acts.FETCH_USER_USER_PERMITS_SUCCESS, payload: permits })
    return permits
  } catch (err) {
    dispatch({ type: acts.FETCH_USER_USER_PERMITS_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const editUserPermitNotes = act(acts.EDIT_USER_PERMIT_NOTES, async ({ permitId, notes }) => {
  const updatedPermit = await fetchData({
    type: 'POST',
    url: urls.admin.USER_PERMIT_NOTES(permitId),
    body: { notes },
  })
  return updatedPermit
})

export const setUserPermitNycAccount = act(
  acts.SET_USER_PERMIT_NYC_ACCOUNT,
  async ({ permitId, nycAccountId }) => {
    const updatedPermit = await fetchData({
      type: 'POST',
      url: urls.admin.USER_PERMIT_NYC_ACCOUNT(permitId),
      body: { nycAccountId },
    })
    return updatedPermit
  },
)

export const setUserPermitNycCard = act(
  acts.SET_USER_PERMIT_NYC_CARD,
  async ({ permitId, nycCardId }) => {
    const updatedPermit = await fetchData({
      type: 'POST',
      url: urls.admin.USER_PERMIT_NYC_CARD(permitId),
      body: { nycCardId },
    })
    return updatedPermit
  },
)

export const manuallyFulfillUserPermit = act(
  acts.MANUALLY_FULFILL_USER_PERMIT,
  async ({ permitId }) => {
    const updatedPermit = await fetchData({
      type: 'POST',
      url: urls.admin.USER_PERMIT_MANUAL_FULFILLMENT(permitId),
    })
    return updatedPermit
  },
)

export const downloadUserPermitsCsv = act(acts.DOWNLOAD_USER_PERMITS_CSV, () =>
  fetchCsv({ url: urls.admin.USER_PERMITS_CSV() }),
)

export const fetchCecs = act(acts.FETCH_CUSTOM_EMAIL_CAPTURES, () =>
  fetchData({ url: urls.admin.CUSTOM_EMAIL_CAPTURES() }),
)

export const fetchCec = act(acts.FETCH_CUSTOM_EMAIL_CAPTURE, ({ id }) =>
  fetchData({ url: urls.admin.CUSTOM_EMAIL_CAPTURE(id) }),
)

export const createCec = act(
  acts.CREATE_CUSTOM_EMAIL_CAPTURE,
  ({
    name,
    slug,
    customerioSegmentId,
    preTitle,
    preCopy,
    preCtaText,
    postTitle,
    postCopy,
    postCtaText,
    postCtaUrl,
    image,
  }) => {
    let body = {
      name,
      slug,
      customerioSegmentId,
      preTitle,
      preCopy,
      preCtaText,
      postTitle,
      postCopy,
      postCtaText,
      postCtaUrl,
    }
    const hasImages = !!image
    if (hasImages) {
      const fd = new FormData()
      fd.append('image', image)
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }
    return fetchData({
      type: 'POST',
      url: urls.admin.CUSTOM_EMAIL_CAPTURES(),
      isUpload: hasImages,
      body,
    })
  },
)

export const updateCec = act(
  acts.UPDATE_CUSTOM_EMAIL_CAPTURE,
  ({
    cecId,
    name,
    slug,
    customerioSegmentId,
    preTitle,
    preCopy,
    preCtaText,
    postTitle,
    postCopy,
    postCtaText,
    postCtaUrl,
    image,
  }) => {
    let body = {
      name,
      slug,
      customerioSegmentId,
      preTitle,
      preCopy,
      preCtaText,
      postTitle,
      postCopy,
      postCtaText,
      postCtaUrl,
      image,
    }
    const hasImages = !!image && typeof image === 'object'
    if (hasImages) {
      const fd = new FormData()
      if (image) {
        fd.append('image', image)
      }
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }
    return fetchData({
      type: 'PATCH',
      url: urls.admin.CUSTOM_EMAIL_CAPTURE(cecId),
      isUpload: hasImages,
      body,
    })
  },
)

export const deleteCec = act(acts.DELETE_CUSTOM_EMAIL_CAPTURE, ({ id }) =>
  fetchData({
    type: 'DELETE',
    url: urls.admin.CUSTOM_EMAIL_CAPTURE(id),
  }),
)

export const createUser = act(acts.CREATE_USER, ({ osid, user }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.USERS(),
    body: user,
  }),
)

export const fetchBtlFeaturedFacilities = act(acts.FETCH_BTL_FEATURED_FACILITIES, () =>
  fetchData({ url: urls.admin.BTL_FEATURED_FACILITIES() }),
)

export const createBtlFeaturedFacility = act(
  acts.CREATE_BTL_FEATURED_FACILITY,
  ({ title, org, venue, url, sport }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.BTL_FEATURED_FACILITIES(),
      body: { title, org, venue, url, sport },
    }),
)

export const updateBtlFeaturedFacility = act(
  acts.UPDATE_BTL_FEATURED_FACILITY,
  ({ facilityId, title, org, venue, url, sport }) => {
    return fetchData({
      type: 'PUT',
      url: urls.admin.BTL_FEATURED_FACILITY(facilityId),
      body: { title, org, venue, url, sport },
    })
  },
)

export const reorderBtlFeaturedFacilities = act(
  acts.REORDER_BTL_FEATURED_FACILITIES,
  ({ facilityIds }) => {
    return fetchData({
      type: 'POST',
      url: urls.admin.REORDER_BTL_FEATURED_FACILITIES(),
      body: { facilityIds },
    })
  },
)

export const deleteBtlFeaturedFacility = act(
  acts.DELETE_BTL_FEATURED_FACILITIES,
  ({ facilityId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.admin.BTL_FEATURED_FACILITY(facilityId),
    }),
)

export const fetchBtlFeaturedHomepageClubs = act(acts.FETCH_BTL_FEATURED_HOMEPAGE_CLUBS, () =>
  fetchData({ url: urls.admin.BTL_FEATURED_HOMEPAGE_CLUBS() }),
)

export const createBtlFeaturedHomepageClub = act(
  acts.CREATE_BTL_FEATURED_HOMEPAGE_CLUB,
  ({ clubId, state, city }) => {
    return fetchData({
      type: 'POST',
      url: urls.admin.BTL_FEATURED_HOMEPAGE_CLUBS(),
      body: { clubId, state, city },
    })
  },
)

export const updateBtlFeaturedHomepageClub = act(
  acts.UPDATE_BTL_FEATURED_HOMEPAGE_CLUB,
  ({ clubId, state, city }) => {
    return fetchData({
      type: 'PATCH',
      url: urls.admin.BTL_FEATURED_HOMEPAGE_CLUB(clubId),
      body: { state, city, clubId },
    })
  },
)

export const deleteBtlFeaturedHomepageClub = act(
  acts.DELETE_BTL_FEATURED_HOMEPAGE_CLUB,
  ({ clubId }) => {
    return fetchData({
      type: 'DELETE',
      url: urls.admin.BTL_FEATURED_HOMEPAGE_CLUB(clubId),
    })
  },
)

export const reorderBtlFeaturedHomepageClubs = act(
  acts.REORDER_BTL_FEATURED_HOMEPAGE_CLUBS,
  ({ clubIds }) => {
    return fetchData({
      type: 'POST',
      url: urls.admin.REORDER_BTL_FEATURED_HOMEPAGE_CLUBS(),
      body: { clubIds },
    })
  },
)

export const fetchBtlFeaturedGeos = act(acts.FETCH_BTL_FEATURED_GEOS, () =>
  fetchData({ url: urls.admin.BTL_FEATURED_GEOS() }),
)

export const createBtlFeaturedGeo = act(
  acts.CREATE_BTL_FEATURED_GEO,
  ({ title, country, state, city, image }) => {
    const attrs = { title, country, state, city }
    let body = { attrs }
    const hasImage = !!image
    if (hasImage) {
      const fd = new FormData()
      fd.append('image', image)
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    return fetchData({
      type: 'POST',
      url: urls.admin.BTL_FEATURED_GEOS(),
      body,
      isUpload: hasImage,
    })
  },
)

export const updateBtlFeaturedGeo = act(
  acts.UPDATE_BTL_FEATURED_GEO,
  ({ geoId, title, country, state, city, image }) => {
    const attrs = { title, country, state, city }
    let body = { attrs }
    const hasImage = image && typeof image !== 'string'
    if (hasImage) {
      const fd = new FormData()
      fd.append('image', image)
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    } else {
      body = { ...attrs, image }
    }
    return fetchData({
      type: 'PUT',
      url: urls.admin.BTL_FEATURED_GEO(geoId),
      body,
      isUpload: hasImage,
    })
  },
)

export const reorderBtlFeaturedGeos = act(acts.REORDER_BTL_FEATURED_GEOS, ({ geoIds }) => {
  return fetchData({
    type: 'POST',
    url: urls.admin.REORDER_BTL_FEATURED_GEOS(),
    body: { geoIds },
  })
})

export const deleteBtlFeaturedGeo = act(acts.DELETE_BTL_FEATURED_GEO, ({ geoId }) =>
  fetchData({
    type: 'DELETE',
    url: urls.admin.BTL_FEATURED_GEO(geoId),
  }),
)

export const fetchAllExploreClubsPositions = act(acts.FETCH_ALL_EXPLORE_CLUBS_POSITIONS, () =>
  fetchData({
    type: 'GET',
    url: urls.admin.EXPLORE_CLUBS_POSITIONS(),
  }),
)

export const createExploreClubsPosition = act(acts.CREATE_CLUB_POSITION, ({ clubId, position }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.EXPLORE_CLUBS_POSITIONS(),
    body: { clubId, position },
  }),
)

export const updateExploreClubsPosition = act(acts.UPDATE_CLUB_POSITION, ({ clubId, position }) =>
  fetchData({
    type: 'PATCH',
    url: urls.admin.EXPLORE_CLUBS_POSITION(clubId),
    body: { position },
  }),
)

export const deleteExploreClubsPosition = act(acts.DELETE_CLUB_POSITION, ({ clubId }) =>
  fetchData({
    type: 'DELETE',
    url: urls.admin.EXPLORE_CLUBS_POSITION(clubId),
  }),
)

export const fetchAllClubs = act(acts.FETCH_ALL_CLUBS, () =>
  fetchData({
    url: urls.admin.ALL_CLUBS(),
  }),
)

export const fetchVenues = act(acts.FETCH_ALL_VENUES, () => fetchData({ url: urls.admin.VENUES() }))

export const fetchBenefits = act(acts.FETCH_ADMIN_BENEFITS, () =>
  fetchData({ url: urls.admin.BENEFITS() }),
)

export const fetchBenefit = act(acts.FETCH_ADMIN_BENEFIT, ({ sig }) =>
  fetchData({ url: urls.admin.BENEFIT(sig) }),
)

export const searchVenues = act(
  acts.SEARCH_VENUES,
  ({ order, searchInput, limit = null, offset = 0 }) => {
    return fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.SEARCH_VENUES(),
        query: {
          _order: order,
          searchInput,
          limit,
          offset,
        },
      }),
    })
  },
)

export const searchBenefits = act(
  acts.SEARCH_BENEFITS,
  ({ order, searchInput, limit = null, offset = 0 }) => {
    return fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.SEARCH_BENEFITS(),
        query: {
          _order: order,
          searchInput,
          limit,
          offset,
        },
      }),
    })
  },
)

export const quickSearchClubs = act(
  acts.QUICK_SEARCH_CLUBS,
  ({ order, searchInput, limit = null, offset = 0 }) => {
    return fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.QUICK_SEARCH_CLUBS(),
        query: {
          _order: order,
          searchInput,
          limit,
          offset,
        },
      }),
    })
  },
)

export const quickSearchPrograms = act(
  acts.QUICK_SEARCH_PROGRAMS,
  ({ order, searchInput, limit = null, offset = 0 }) => {
    return fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.QUICK_SEARCH_PROGRAMS(),
        query: {
          _order: order,
          searchInput,
          limit,
          offset,
        },
      }),
    })
  },
)

export const fetchOrgs = act(acts.FETCH_ALL_ORGS, () => fetchData({ url: urls.admin.ORGS() }))

export const searchOrgs = act(
  acts.SEARCH_ORGS,
  ({ order, searchInput, limit = null, offset = 0 }) => {
    return fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.SEARCH_ORGS(),
        query: {
          _order: order,
          searchInput,
          limit,
          offset,
        },
      }),
    })
  },
)

export const fetchOrg = act(acts.FETCH_ORG, ({ sig }) =>
  fetchData({
    url: urls.admin.ORG(sig),
  }),
)

export const createOrg = act(acts.CREATE_ORG, ({ org }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.ORGS(),
    body: org,
  }),
)

export const updateOrg = act(acts.UPDATE_ORG, ({ sig, org }) =>
  fetchData({
    type: 'PUT',
    url: urls.admin.ORG(sig),
    body: org,
  }),
)

export const addOrgTeamMembership = act(acts.ADD_ORG_TEAM_MEMBERSHIP, ({ orgSig, userId }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.ORG_TEAM_MEMBERS(orgSig),
    body: { userId },
  }),
)

export const removeOrgTeamMembership = act(
  acts.REMOVE_ORG_TEAM_MEMBERSHIP,
  ({ orgSig, membershipId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.admin.ORG_TEAM_MEMBER(orgSig, membershipId),
    }),
)

export const updateVenue = act(acts.UPDATE_VENUE, ({ venueId, venue }) => {
  let hasNewImages = false
  let attrs = { ...venue }
  let body = { attrs }
  if (Array.isArray(venue.images)) {
    const existingImages = venue.images.filter(img => !img.isNew)
    attrs.images = existingImages

    const newImages = venue.images.filter(img => img.isNew)
    hasNewImages = newImages.length > 0
    if (hasNewImages) {
      const fd = new FormData()
      newImages.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
  }
  return fetchData({
    type: 'PATCH',
    url: urls.admin.VENUE(venueId),
    body,
    isUpload: hasNewImages,
  })
})

export const createVenue = act(acts.CREATE_VENUE, ({ orgSig, venue, homepage }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.ORG_VENUES(orgSig),
    body: { venue, homepage },
  }),
)

export const fetchVenue = act(acts.FETCH_VENUE, ({ venueSig }) =>
  fetchData({ url: urls.admin.VENUE(venueSig) }),
)

export const updateVenueSettings = act(acts.UPDATE_VENUE_SETTINGS, ({ venueSig, settings }) =>
  fetchData({
    type: 'PATCH',
    url: urls.admin.VENUE_SETTINGS(venueSig),
    body: { settings },
  }),
)

export const updateVenueHomepageSettings = act(
  acts.UPDATE_VENUE_HOMEPAGE_SETTINGS,
  ({ venueSig, settings }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.VENUE_HOMEPAGE_SETTINGS(venueSig),
      body: { settings },
    }),
)

export const createVenueAvailableLesson = act(
  acts.CREATE_VENUE_AVAILABLE_LESSON,
  ({ venueSig, sport, notifyEmails }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.VENUE_AVAILABLE_LESSONS(venueSig),
      body: { sport, notifyEmails },
    }),
)

export const deleteVenueAvailableLesson = act(
  acts.DELETE_VENUE_AVAILABLE_LESSON,
  ({ venueSig, availableLessonId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.admin.VENUE_AVAILABLE_LESSON(venueSig, availableLessonId),
    }),
)

export const addOrgFeeRule = act(acts.ADD_ORG_FEE_RULE, ({ orgSig, type, percentAmount }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.ORG_FEE_RULES(orgSig),
    body: { type, percentAmount },
  }),
)

export const removeOrgFeeRule = act(acts.REMOVE_ORG_FEE_RULE, ({ orgSig, ruleId }) =>
  fetchData({
    type: 'DELETE',
    url: urls.admin.ORG_FEE_RULE(orgSig, ruleId),
  }),
)

export const fetchAllConsts = act(acts.FETCH_ALL_CONSTS, () =>
  fetchData({ url: urls.admin.ALL_CONSTS() }),
)

export const fetchConstsByName = act(acts.FETCH_CONSTS_BY_NAME, ({ name }) =>
  fetchData({ url: urls.admin.CONSTS_BY_NAME(name) }),
)

export const metricPerResourceBookingsByMonth = act(
  acts.METRIC_PER_RESOURCE_BOOKINGS_BY_MONTH,
  ({ orgId, venueId, benefitId, countMethod, checkedIn }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.metrics.PER_RESOURCE_BOOKINGS_BY_MONTH(),
      body: { orgId, venueId, benefitId, countMethod, checkedIn },
    }),
)

export const metricPerResourceBookingsByMonthQuery = act(
  acts.METRIC_PER_RESOURCE_BOOKINGS_BY_MONTH_QUERY,
  ({ orgId, venueId, benefitId, countMethod, checkedIn }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.metrics.PER_RESOURCE_BOOKINGS_BY_MONTH_QUERY(),
      body: { orgId, venueId, benefitId, countMethod, checkedIn },
    }),
)

export const metricPerParticipantBookingsByMonth = act(
  acts.METRIC_PER_PARTICIPANT_BOOKINGS_BY_MONTH,
  ({
    orgId,
    venueId,
    benefitId,
    countMethod,
    checkedIn,
    bookingType,
    groupByProgramName,
    groupByResType,
  }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.metrics.PER_PARTICIPANT_BOOKINGS_BY_MONTH(),
      body: {
        orgId,
        venueId,
        benefitId,
        countMethod,
        checkedIn,
        bookingType,
        groupByProgramName,
        groupByResType,
      },
    }),
)

export const metricPerParticipantBookingsByMonthQuery = act(
  acts.METRIC_PER_PARTICIPANT_BOOKINGS_BY_MONTH_QUERY,
  ({
    orgId,
    venueId,
    benefitId,
    countMethod,
    checkedIn,
    bookingType,
    groupByProgramName,
    groupByResType,
  }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.metrics.PER_PARTICIPANT_BOOKINGS_BY_MONTH_QUERY(),
      body: {
        orgId,
        venueId,
        benefitId,
        countMethod,
        checkedIn,
        bookingType,
        groupByProgramName,
        groupByResType,
      },
    }),
)

export const fetchVenueHomepageCallouts = act(acts.FETCH_VENUE_HOMEPAGE_CALLOUTS, ({ venueSig }) =>
  fetchData({
    url: urls.admin.VENUE_HOMEPAGE_CALLOUTS(venueSig),
  }),
)

export const createVenueHomepageCallout = act(
  acts.CREATE_VENUE_HOMEPAGE_CALLOUT,
  ({ venueSig, callout, image }) => {
    let body = { callout }
    if (image) {
      const fd = new FormData()
      fd.append('image', image)
      fd.append('attrs', JSON.stringify(callout))
      body = fd
    }
    return fetchData({
      type: 'POST',
      url: urls.admin.VENUE_HOMEPAGE_CALLOUTS(venueSig),
      body,
      isUpload: !!image,
    })
  },
)

export const updateVenueHomepageCallout = act(
  acts.UPDATE_VENUE_HOMEPAGE_CALLOUT,
  ({ venueSig, calloutId, callout, image }) => {
    let body = callout
    if (image) {
      const fd = new FormData()
      fd.append('image', image)
      fd.append('attrs', JSON.stringify(callout))
      body = fd
    }
    return fetchData({
      type: 'PATCH',
      url: urls.admin.VENUE_HOMEPAGE_CALLOUT(venueSig, calloutId),
      body,
      isUpload: !!image,
    })
  },
)

export const deleteVenueHomepageCallout = act(
  acts.DELETE_VENUE_HOMEPAGE_CALLOUT,
  ({ venueSig, calloutId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.admin.VENUE_HOMEPAGE_CALLOUT(venueSig, calloutId),
    }),
)

export const updateVenueHomepageCalloutsOrder = act(
  acts.UPDATE_VENUE_HOMEPAGE_CALLOUTS_ORDER,
  ({ venueSig, callouts }) => {
    return fetchData({
      type: 'PATCH',
      url: urls.admin.VENUE_HOMEPAGE_CALLOUTS(venueSig),
      body: { callouts },
    })
  },
)

export const generateUserPasswordResetLink = act(
  acts.GENERATE_USER_PASSWORD_RESET_LINK,
  ({ userId }) =>
    fetchData({ type: 'POST', url: urls.admin.GENERATE_USER_PASSWORD_RESET_LINK(userId) }),
)

export const updateBenefitVenueSettings = act(
  acts.UPDATE_BENEFIT_VENUE_SETTINGS,
  ({ benefitVenueId, settings }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.BENEFIT_VENUE_SETTINGS(benefitVenueId),
      body: { settings },
    }),
)

export const fetchBenefitBeneficiaryGroups = act(acts.FETCH_BENEFICIARY_GROUPS, ({ benefitId }) =>
  fetchData({
    url: urls.admin.BENEFIT_BENEFICIARY_GROUPS(benefitId),
  }),
)

export const fetchVenueResources = act(acts.FETCH_VENUE_RESOURCES, ({ venueSig }) =>
  fetchData({
    url: urls.admin.VENUE_RESOURCES(venueSig),
  }),
)

export const updateBenefitVenuePermittedResources = act(
  acts.UPDATE_BENEFIT_VENUE_PERMITTED_RESOURCES,
  ({ benefitVenueId, resourceIds }) =>
    fetchData({
      type: 'PUT',
      url: urls.admin.BENEFIT_VENUE_PERMITTED_RESOURCES(benefitVenueId),
      body: { resourceIds },
    }),
)

export const updateBenefitVenuePermittedBeneficiaryGroups = act(
  acts.UPDATE_BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS,
  ({ benefitVenueId, beneficiaryGroupIds }) =>
    fetchData({
      type: 'PUT',
      url: urls.admin.BENEFIT_VENUE_PERMITTED_BENEFICIARY_GROUPS(benefitVenueId),
      body: { beneficiaryGroupIds },
    }),
)

export const updateBeneficiary = act(
  acts.UPDATE_BENEFICIARY,
  ({ beneficiaryId, benefitSig, ...update }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.BENEFIT_BENEFICIARY(benefitSig, beneficiaryId),
      body: { ...update },
    }),
)

export const fetchPackage = act(acts.FETCH_PACKAGE, ({ packageId }) =>
  fetchData({ url: urls.admin.PACKAGE(packageId) }),
)

export const fetchPackageSets = act(acts.FETCH_PACKAGE_SETS, () =>
  fetchData({ url: urls.admin.PACKAGE_SETS() }),
)

export const fetchPackageSet = act(acts.FETCH_PACKAGE_SET, ({ setId }) =>
  fetchData({ url: urls.admin.PACKAGE_SET(setId) }),
)

export const createPackageSet = act(acts.CREATE_PACKAGE_SET, ({ packageSet }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.PACKAGE_SETS(),
    body: packageSet,
  }),
)

export const updatePackageSet = act(acts.UPDATE_PACKAGE_SET, ({ id, packageSet }) =>
  fetchData({
    type: 'PUT',
    url: urls.admin.PACKAGE_SET(id),
    body: packageSet,
  }),
)

export const deletePackageSet = act(acts.DELETE_PACKAGE_SET, ({ id }) =>
  fetchData({ type: 'DELETE', url: urls.admin.PACKAGE_SET(id) }),
)

export const fetchUserPackage = act(acts.FETCH_USER_PACKAGE, ({ userPackageId }) =>
  fetchData({ url: urls.admin.USER_PACKAGE(userPackageId) }),
)

export const countUserPackageItemUses = act(
  acts.COUNT_USER_PACKAGE_ITEM_USES,
  ({ userPackageId, userPackageItemId }) =>
    fetchData({
      url: urls.admin.USER_PACKAGE_ITEM_USES(userPackageId, userPackageItemId),
    }),
)

export const updateUserPackageItem = act(
  acts.UPDATE_USER_PACKAGE_ITEM,
  ({ userPackageId, userPackageItemId, update }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.USER_PACKAGE_ITEM(userPackageId, userPackageItemId),
      body: update,
    }),
)

export const fetchBeneficiaryGroup = act(
  acts.FETCH_BENEFICIARY_GROUP,
  ({ benefitSig, beneficiaryGroupId }) =>
    fetchData({
      url: urls.admin.BENEFIT_BENEFICIARY_GROUP(benefitSig, beneficiaryGroupId),
    }),
)

export const fetchBeneficiaryGroupUsers = act(
  acts.FETCH_BENEFICIARY_GROUP_USERS,
  ({ benefitSig, beneficiaryGroupId, order, limit = null, offset = 0 }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.admin.BENEFIT_BENEFICIARY_GROUP_USERS(benefitSig, beneficiaryGroupId),
        query: {
          _limit: limit,
          _offset: offset,
        },
      }),
    }),
)

export const createBeneficiaryGroup = act(
  acts.CREATE_BENEFICIARY_GROUP,
  ({ benefitSig, ...attrs }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.BENEFIT_BENEFICIARY_GROUPS(benefitSig),
      body: { ...attrs },
    }),
)

export const updateBeneficiaryGroup = act(
  acts.UPDATE_BENEFICIARY_GROUP,
  ({ benefitSig, beneficiaryGroupId, ...attrs }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.BENEFIT_BENEFICIARY_GROUP(benefitSig, beneficiaryGroupId),
      body: { ...attrs },
    }),
)

export const removeBeneficiaryGroup = act(
  acts.REMOVE_BENEFICIARY_GROUP,
  ({ benefitSig, beneficiaryGroupId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.admin.BENEFIT_BENEFICIARY_GROUP(benefitSig, beneficiaryGroupId),
    }),
)

export const manuallyAddUserToBeneficiaryGroup = act(
  acts.MANUALLY_ADD_USER_TO_BENFICIARY_GROUP,
  ({ benefitSig, beneficiaryGroupId, userId }) =>
    fetchData({
      type: 'POST',
      url: urls.admin.BENEFIT_BENEFICIARY_GROUP_USERS(benefitSig, beneficiaryGroupId),
      body: { userId },
    }),
)

export const addBenefitVenue = act(acts.ADD_BENEFIT_VENUE, ({ benefitSig, ...attrs }) =>
  fetchData({
    type: 'POST',
    url: urls.admin.BENEFIT_VENUES(benefitSig),
    body: { ...attrs },
  }),
)

export const updateBenefitVenue = act(
  acts.UPDATE_BENEFIT_VENUE,
  ({ benefitSig, benefitVenueId, ...attrs }) =>
    fetchData({
      type: 'PATCH',
      url: urls.admin.BENEFIT_VENUE(benefitSig, benefitVenueId),
      body: { ...attrs },
    }),
)

export const deleteBenefitVenue = act(acts.DELETE_BENEFIT_VENUE, ({ benefitSig, benefitVenueId }) =>
  fetchData({
    type: 'DELETE',
    url: urls.admin.BENEFIT_VENUE(benefitSig, benefitVenueId),
  }),
)

export const downloadInventoryCapacity =
  ({ startDate, endDate }) =>
  async dispatch => {
    dispatch({ type: acts.DOWNLOAD_INVENTORY_CAPACITY_PENDING })
    try {
      const blob = await fetchBlob({
        url: urls.admin.DOWNLOAD_INVENTORY_CAPACITY({ startDate, endDate }),
      })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `btl-inventory-capacity-${startDate}-to-${endDate}.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url)

      dispatch({ type: acts.DOWNLOAD_INVENTORY_CAPACITY_SUCCESS, payload: {} })
    } catch (error) {
      console.error('Download failed:', error)
      dispatch({ type: acts.DOWNLOAD_INVENTORY_CAPACITY_FAILURE, payload: error })
    }
  }
