import { useCallback } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'
import { SortableClubItem } from './SortableClubItem'

const DraggableClubList = ({ items, onReorder }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = useCallback(
    event => {
      const { active, over } = event

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(item => item.id.toString() === active.id)
        const newIndex = items.findIndex(item => item.id.toString() === over.id)
        onReorder(arrayMove(items, oldIndex, newIndex))
      }
    },
    [items, onReorder],
  )

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, 320px)',
          gap: '32px',
          position: 'relative',
          minHeight: '100px',
          justifyContent: 'flex-start',
        }}
      >
        <SortableContext
          items={items.map(item => item.id.toString())}
          strategy={rectSortingStrategy}
        >
          {items.map((item, index) => (
            <SortableClubItem key={item.id} id={item.id.toString()} item={item} index={index} />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  )
}

export default DraggableClubList
