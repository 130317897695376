import React, { useEffect, useReducer } from 'react'
import { DatePicker } from 'antd'
import { InputGroup, Input, Title, Desc } from './step'
import Attr from '../../../components/Attr'
import dayjs from 'dayjs'
import styled from 'styled-components'

const PositionInput = styled(Input)`
  .ant-input-wrapper.ant-input-group {
    width: ${props => props.theme.width[5]};
  }
`

const initState = ({ featuredWindowStart, featuredWindowEnd, featuredWindowPosition }) => ({
  featuredWindowStart,
  featuredWindowEnd,
  featuredWindowPosition,
})

const reducer = (state, action) => ({ ...state, ...action })

const Step = ({ onChange, clinic }) => {
  const [state, dispatch] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state, onChange])

  return (
    <>
      <InputGroup>
        <Title>Featured Window</Title>
        <Desc>
          Provide a date range during which this program will be featured on the club it belongs to.
          In case the end date is left empty, the program will be featured til the program is not
          bookable anymore. On the other hand, if it doens't have a start date, the window will not
          work.
        </Desc>
        <Attr name="Start date">
          <DatePicker
            size="large"
            placeholder={`Start date`}
            disabledDate={current => current && current <= dayjs().startOf('day')}
            defaultValue={state.featuredWindowStart ? dayjs(state.featuredWindowStart) : null}
            onChange={(date, dateString) =>
              dispatch({ featuredWindowStart: Boolean(dateString) ? dateString : null })
            }
          />
        </Attr>
        <Attr name="End date">
          <DatePicker
            size="large"
            placeholder={`End date`}
            disabledDate={current => current && current <= dayjs().startOf('day')}
            defaultValue={state.featuredWindowEnd ? dayjs(state.featuredWindowEnd) : null}
            onChange={(date, dateString) => {
              dispatch({ featuredWindowEnd: Boolean(dateString) ? dateString : null })
            }}
          />
        </Attr>
        <Attr name="Position">
          <PositionInput
            value={state.featuredWindowPosition}
            addonAfter="order to be shown"
            type="number"
            min={0}
            onChange={e => dispatch({ featuredWindowPosition: +e.target.value })}
          />
        </Attr>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <>
      <Attr name="Start Date">
        <div>{program.featuredWindowStart}</div>
      </Attr>
      <Attr name="End Date">
        <div>{program.featuredWindowEnd}</div>
      </Attr>
      <Attr name="Position">
        <div>{program.featuredWindowPosition}</div>
      </Attr>
    </>
  )
}

const component = { step: Step, Viewer, complete: () => true, applicable: () => true }

export default component
