import store from 'store'
import { parseRemoteError } from './error'
import { EMPTY_RESPONSE, urls } from '../constants'

const { API_BASE_URL } = urls

const defaultHeaders = url => {
  let headers = {
    'Content-Type': 'application/json',
  }
  let token
  if (store.get('user')) {
    token = store.get('user').token
  }
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return headers
}

export const fetchCsv = async ({ url = '', type = 'GET', headers }) => {
  try {
    const mergeHeaders = { ...defaultHeaders(url), ...headers }
    const args = { method: type, headers: mergeHeaders }

    const res = await fetch(`${API_BASE_URL()}${url}`, args)
    const status = res.status
    const data = await res.text()

    if (![200, 201].includes(status)) {
      if (data.errors) {
        throw data.errors
      }
      throw data.error
    }

    return data
  } catch (err) {
    console.log(err)
    throw err
  }
}

const fetchBase = async ({ url = '', type = 'GET', body = null, headers, isUpload = false }) => {
  try {
    const mergeHeaders = Object.assign({}, defaultHeaders(url), headers)
    const args = { method: type, headers: mergeHeaders }

    if (body && !isUpload) {
      args.body = JSON.stringify(body)
    }

    if (body && isUpload) {
      // if uploading, do not send content-type header
      // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
      delete args.headers['Content-Type']
      args.body = body
    }

    const res = await fetch(`${API_BASE_URL()}${url}`, args)
    const status = res.status
    const text = await res.text()
    const data = text ? JSON.parse(text) : EMPTY_RESPONSE

    // user token expired
    //if (status === 401) {
    //  store.dispatch(logoutAction())
    //  throw data.message
    //}

    if (![200, 201].includes(status)) {
      if (data.errors) {
        throw data.errors
      }
      throw data.error
    }

    return data
  } catch (e) {
    console.log(e)
    throw parseRemoteError(e)
  }
}

export const fetchBlob = async ({ url = '', type = 'GET', headers }) => {
  try {
    const mergeHeaders = { ...defaultHeaders(url), ...headers }
    const args = { method: type, headers: mergeHeaders }

    const res = await fetch(`${API_BASE_URL()}${url}`, args)
    if (!res.ok) {
      throw new Error('Network response was not ok')
    }
    return res.blob()
  } catch (err) {
    console.log(err)
    throw err
  }
}

export default fetchBase
