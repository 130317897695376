import { useEffect } from 'react'
import { Menu, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../../constants'
import { useStatus, useStatusMsg } from '../../../../reducers'
import { clearStatus } from '../../../../actions/status'
import { deleteBtlFeaturedHomepageClub } from '../../../../actions/admin'

const ActionsMenu = ({ featuredHomepageClub, onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_BTL_FEATURED_HOMEPAGE_CLUB)

  useStatusMsg(deleteStatus, {
    pending: 'Deleting featured homepage club...',
    error: 'Failed to delete featured homepage club',
    success: 'Featured homepage club deleted',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_BTL_FEATURED_HOMEPAGE_CLUB)), [dispatch])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(featuredHomepageClub)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete featured homepage club?',
        okText: 'Delete',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false,
          disabled: deleteStatus.pending || false,
        },
        content: <p>Are you sure you want to delete this featured homepage club?</p>,
        onOk: () => {
          dispatch(deleteBtlFeaturedHomepageClub({ clubId: featuredHomepageClub.id }))

          if (typeof onDelete === 'function') {
            onDelete(featuredHomepageClub)
          }
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

export default ActionsMenu
