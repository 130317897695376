import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, Modal, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import * as urls from '../../util/url'
import { PROGRAM } from '../../constants/marketingLabels'
import { fetchProgramAttributes } from '../../actions/partner'
import { updateProgram } from '../../actions/class'
import { useStatus, useStatusMsg } from '../../reducers'
import { actions as acts } from '../../constants'
import ProgramType from '../CreateClass/components/ProgramType'
import BasicInformation from '../CreateClass/components/BasicInformation'
import Venue from '../CreateClass/components/Venue'
import Location from '../CreateClass/components/Location'
import Players from '../CreateClass/components/Players'
import PricingType from '../CreateClass/components/PricingType'
import Timing from '../CreateClass/components/Timing'
import Inclusions from '../CreateClass/components/Inclusions'
import CancellationPolicy from '../CreateClass/components/CancellationPolicy'
import PublishDate from '../CreateClass/components/PublishDate'
import ImageUploads from '../CreateClass/components/ImageUploads'
import Hidden from '../CreateClass/components/Hidden'
import Attributes from '../CreateClass/components/Attributes'
import Host from '../CreateClass/components/Host'
import Club from '../CreateClass/components/Club'
import Sponsors from '../CreateClass/components/Sponsors'
import Sports from '../CreateClass/components/Sports'
import ClubMembership from '../CreateClass/components/ClubMembership'
import CardRestriction from '../CreateClass/components/CardRestriction'
import { BackTrack, Footer, Next } from '../CreateClass/components/step'
import Container from './Container'
import styled, { ThemeContext } from 'styled-components'
import capitalize from 'capitalize'
import { deleteProgram } from '../../actions/class'
import { push } from 'connected-react-router'
import { paths } from '../../constants'
import { Cta as BTLButton, Button } from '../../components/common'
import FeaturedWindow from '../CreateClass/components/FeaturedWindow'

const SectionTitle = styled.h2`
  font-size: ${props => props.theme.font.size[5]}
  font-weight: ${props => props.theme.font.weight[7]}
  margin: 0;
`

const EditHeader = styled.div`
  display: flex;
  align-items: center;

  button {
    cursor: pointer;
    visibility: hidden;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
`
const EditorContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
  background-color: ${props => props.theme.color.gray[0]};
`
const EditableContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
  &:hover button {
    visibility: visible;
  }
`

const EditableValue = ({ name, children, program, editor, complete }) => {
  const dispatch = useDispatch()
  const { osid } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const onClickEdit = () => setIsEditing(true)
  const [state, setState] = useState({})
  const [stepComplete, setStepComplete] = useState(null)
  const status = useStatus(acts.UPDATE_PROGRAM)

  useStatusMsg(status, {
    error: e => `Failed to update program: ${e}`,
  })

  useEffect(() => {
    const checkComplete = async () => {
      const isComplete = await complete({ ...program, ...state }, osid)
      setStepComplete(isComplete)
    }
    checkComplete()
  }, [program, state, osid])

  const onSave = async () => {
    if (state) {
      dispatch(updateProgram(osid, program.id, state)).then(() => {
        setState({})
        setIsEditing(false)
      })
    }
  }

  if (isEditing) {
    const Editor = editor
    return (
      <EditorContainer>
        <Editor clinic={program} onChange={e => setState(e)} />
        <Footer>
          <BackTrack onCancel={() => setIsEditing(false)} />
          <Next disabled={!stepComplete || status.pending} onClick={onSave}>
            {status.pending ? 'Saving...' : 'Save'}
          </Next>
        </Footer>
      </EditorContainer>
    )
  }
  return (
    <EditableContainer>
      <EditHeader>
        {name && <SectionTitle style={{ marginRight: '.5em' }}>{name}</SectionTitle>}
        <Button onClick={onClickEdit}>Edit</Button>
      </EditHeader>
      {children}
    </EditableContainer>
  )
}

const sections = [
  { name: `${capitalize(PROGRAM)}`, section: ProgramType },
  { name: 'Details', section: BasicInformation },
  { name: 'Venue', section: Venue },
  { name: 'Clubs', section: Club },
  { name: 'Club memberships', section: ClubMembership },
  { name: 'Card restriction', section: CardRestriction },
  { name: 'Sponsors', section: Sponsors },
  { name: 'Sports', section: Sports },
  { name: 'Host', section: Host },
  { name: 'Attributes', section: Attributes },
  { name: 'Hidden', section: Hidden },
  { name: 'Schedule', section: Timing },
  { name: 'Featured Window', section: FeaturedWindow },
  { name: 'Number of participants', section: Players },
  { name: 'Pricing', section: PricingType },
  { name: "What's included", section: Inclusions },
  { name: 'Cancellation policy', section: CancellationPolicy },
  { name: 'Publish date', section: PublishDate },
  { name: 'Images', section: ImageUploads },
  { name: 'Location', section: Location },
]

const Overview = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const program = useSelector(s => s.currentProgram)

  const [attributes, setAttributes] = useState([])
  const status = useStatus(acts.DELETE_PROGRAM)
  useStatusMsg(status, {
    pending: 'Deleting event...',
    error: 'Failed to delete event',
    success: 'Event deleted',
  })

  const attributesExist = Array.isArray(attributes) && attributes.length > 0

  useEffect(() => {
    dispatch(fetchProgramAttributes(osid)).then(res => setAttributes(res))
  }, [])

  // TODO fix back button woes?
  // (this page re-renders with a blank program when using the back button)
  if (!program.id) {
    return null
  }

  const delProgram = () => {
    dispatch(deleteProgram(osid, program.id)).then(() => dispatch(push(paths.org.PROGRAMS(osid))))
  }

  const handleActionMenu = e => {
    if (e.key === 'delete') {
      if (program.bookings.length > 0) {
        Modal.error({
          title: 'Cannot delete program',
          content: 'This program cannot be deleted because it contains bookings',
        })
      } else {
        Modal.confirm({
          title: 'Delete program?',
          okText: 'Delete',
          okButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: delProgram,
        })
      }
    } else if (e.key === 'duplicate') {
      // redirect to duplication page
      dispatch(push(paths.org.DUPLICATE(osid, program.id)))
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="duplicate">Duplicate</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )

  const elems = sections
    .map(s => {
      const Viewer = s.section.Viewer
      // TODO make conditionally showing/hiding steps a bit more generic
      if (s.name === 'Attributes' && !attributesExist) {
        return null
      }
      return (
        <EditableValue
          key={s.name}
          name={s.name}
          editor={s.section.step}
          complete={s.section.complete}
          program={program}
        >
          <Viewer program={program} />
        </EditableValue>
      )
    })
    .filter(Boolean)

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing[1],
        }}
      >
        <a
          target="_blank"
          href={urls.mainSiteProgram(program.id)}
          style={{ display: 'block', paddingRight: theme.spacing[2] }}
          rel="noreferrer"
        >
          View on {urls.mainSite()}
        </a>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <BTLButton>
            Actions <DownOutlined />
          </BTLButton>
        </Dropdown>
      </div>
      <div>{elems}</div>
    </Container>
  )
}

export default Overview
