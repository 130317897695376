import React, { useContext, createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Input,
  Radio,
  Checkbox,
  message,
  Button,
  Modal,
  Menu,
  Dropdown,
  Tabs,
  Tag,
  Breadcrumb,
  Tooltip,
  Progress,
  Divider,
} from 'antd'
import {
  ExclamationCircleOutlined,
  SyncOutlined,
  EllipsisOutlined,
  DownOutlined,
  LoadingOutlined,
  ExportOutlined,
  EditFilled,
} from '@ant-design/icons'
import { Switch, Route, Link, useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { patterns, actions as acts, paths, features } from '../../constants'
import { roles } from '../../constants/club'
import * as orgActs from '../../actions/org'
import { clearStatus } from '../../actions/status'
import * as urlUtils from '../../util/url'
import { downloadDataAsFile } from '../../util/download'
import { useStatus, useStatusMsg } from '../../reducers'
import { formatDateTimeTz } from '../../util/time'
import { Label, Cta, Button as Btn, Head, Title, PaddedContainer } from '../../components/common'
import { MediumInfo } from '../../components/info'
import ReactHtmlParser from 'react-html-parser'
import Attr from '../../components/Attr'
import UserSelect from '../../components/UserSelect'
import BadgeTitle from '../../components/BadgeTitle'
import ClubRoleSelect from '../../components/ClubRoleSelect'
import ImagePreview from '../../components/ImagePreview'
import AttributesViewer from '../../components/AttributesViewer'
import { SponsorItem } from '../../components/sponsor'
import { ProgramList } from '../../components/program'
import moment from 'moment-timezone'
import { usePosts, Posts } from '../../components/posts'
import { Videos, useVideos } from '../../components/videos'
import { validate } from 'uuid'
import ClubShop from './ClubShop'
import ClubSettings from './ClubSettings'
import { ThemeContext } from 'styled-components'
import { Reorderable } from '../../components/Reorderable'
import styled from 'styled-components'
import ClubProgramsList from './ClubProgramsList'
import { groupProgramsByDate } from './utils/groupProgramsByDate'

const { TabPane } = Tabs

export const ClubContext = createContext(null)

const emptyImage = img => {
  if (img) {
    return Object.keys(img).length === 0
  }
  return true
}

const Overview = () => {
  const { club } = useContext(ClubContext)
  const theme = useContext(ThemeContext)

  return (
    <MediumInfo title="Overview" style={{ marginTop: 0 }}>
      <Attr name="Created">
        <div>{formatDateTimeTz(club.createdAt)}</div>
      </Attr>
      <Attr name="Description">
        <div>{ReactHtmlParser(club.description)}</div>
      </Attr>
      <Attr name="Tagline">
        <div>{club.tagline ? club.tagline : <i>None</i>}</div>
      </Attr>
      <Attr name="Icon image">
        <div>
          {!emptyImage(club.icon) && <ImagePreview asset={club.icon} width="auto" height="150px" />}
          {emptyImage(club.icon) && <i>No icon</i>}
        </div>
      </Attr>
      <Attr name="Cover image">
        <div>
          {!emptyImage(club.cover) && (
            <ImagePreview asset={club.cover} width="auto" height="150px" />
          )}
          {emptyImage(club.cover) && <i>No cover</i>}
        </div>
      </Attr>
      <Attr name="Feed image">
        <div>
          {!emptyImage(club.feedImage) && (
            <ImagePreview asset={club.feedImage} width="auto" height="150px" />
          )}
        </div>
      </Attr>
      <Attr name="Attributes">
        <AttributesViewer attributes={club.attributes} />
      </Attr>
      <Attr name="Sponsors">
        <div>
          <Sponsors sponsors={club.sponsors} />
        </div>
      </Attr>
      <Attr name="Orgs">
        {club.orgs?.length ? (
          <div>
            {club.orgs.map(org => (
              <div key={org.id}>{org.name}</div>
            ))}
          </div>
        ) : (
          <i style={{ display: 'block' }}>No orgs</i>
        )}
      </Attr>
      <Attr name="Venues">
        <div>
          {club.venues && club.venues.length > 0 ? (
            club.venues.map(cv => (
              <div key={cv.id} style={{ marginBottom: theme.spacing[1] }}>
                {cv.venue.name}
              </div>
            ))
          ) : (
            <i>No venues</i>
          )}
        </div>
      </Attr>
      <Attr name="Benefit">
        <div>
          {club.benefit ? (
            <span>
              {club.benefit.name} <small>{club.benefit.sid}</small>
            </span>
          ) : (
            <i>No benefit</i>
          )}
        </div>
      </Attr>
      <Attr name="Programs" label="Specific programs added to the club">
        {club.programs?.length ? (
          <div>
            <ClubProgramsList club={club} />
          </div>
        ) : (
          <i style={{ display: 'block' }}>No programs</i>
        )}
      </Attr>
    </MediumInfo>
  )
}

const Sponsors = ({ sponsors }) => {
  if (!Array.isArray(sponsors) || sponsors.length === 0) {
    return <i>No sponsors</i>
  }
  const spns = sponsors.map(spn => (
    <div key={spn.id}>
      <SponsorItem sponsor={spn.sponsor} link />
    </div>
  ))
  return <div>{spns}</div>
}

const Programs = () => {
  const { club } = useContext(ClubContext)
  const { programs } = club
  const hasPrograms = programs && programs.length > 0

  const label =
    hasPrograms && programs.length === 1
      ? features.program.name.singular.toLowerCase()
      : features.program.name.plural.toLowerCase()

  const groupedProgramsByDate = groupProgramsByDate(club.programs)
  return (
    <MediumInfo
      title={features.program.name.plural}
      style={{ marginTop: 0 }}
      info={`${programs.length} ${label} (Specific events added to the club)`}
    >
      {hasPrograms ? (
        Array.from(groupedProgramsByDate).map(([date, programs]) => (
          <div key={date}>
            <h3>{date}</h3>
            <ProgramList programs={programs} />
            <Divider style={{ marginBottom: '12px', marginTop: '0px' }} />
          </div>
        ))
      ) : (
        <i>No programs</i>
      )}
    </MediumInfo>
  )
}

const Members = () => {
  const dispatch = useDispatch()
  const { osid } = useParams()
  const { club, setClub } = useContext(ClubContext)
  const [editingMember, setEditingMember] = useState(null)
  const status = {
    remove: useStatus(acts.REMOVE_CLUB_MEMBER),
  }

  useStatusMsg(status.remove, {
    pending: 'Removing member...',
    success: 'Member removed',
    error: 'Failed to remove member',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.REMOVE_CLUB_MEMBER))
    }
  }, [])

  const onRemoveMember = (clubId, userId) => () => {
    dispatch(orgActs.removeClubMember(osid, clubId, userId))
      .then(members => setClub(prev => ({ ...prev, members })))
      .catch(e => console.log(e))
  }

  const handleActionMenu = member => e => {
    if (e.key === 'edit') {
      setEditingMember(member)
    } else if (e.key === 'remove-member') {
      Modal.confirm({
        title: `Remove ${member.user.name} from ${club.name}?`,
        okText: 'Remove',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onRemoveMember(club.id, member.user.id),
      })
    }
  }

  const actionMenu = member => (
    <Menu onClick={handleActionMenu(member)}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item danger key="remove-member">
        Remove
      </Menu.Item>
    </Menu>
  )

  const members = club.members?.map(m => (
    <div
      key={m.id}
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 0.3fr 0.1fr', marginBottom: '2em' }}
    >
      <div style={{ marginRight: '1em' }}>
        {m.user.name}
        <small style={{ display: 'block' }}>{m.user.email}</small>
      </div>
      <div>{m.role ? m.role : 'Member'}</div>
      <div>{m.createdAt}</div>
      <div>
        <Dropdown overlay={actionMenu(m)} trigger={['click']} placement="bottomRight">
          <Button size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  ))

  return (
    <MediumInfo
      title="Members"
      style={{ marginTop: 0 }}
      info={`${members.length} member${members.length === 1 ? '' : 's'}`}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 0.3fr 0.1fr',
          marginBottom: '2em',
          textTransform: 'uppercase',
          fontSize: '11px',
        }}
      >
        <span>Name</span>
        <span>Role</span>
        <span>Joined</span>
        <div></div>
      </div>
      <div>{members}</div>
      {editingMember && (
        <EditMemberModal
          member={editingMember}
          complete={members => {
            if (members) {
              setClub(prev => ({ ...prev, members }))
            }
            setEditingMember(null)
          }}
        />
      )}
    </MediumInfo>
  )
}

const EditMemberModal = ({ member, complete }) => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [role, setRole] = useState(member.role)
  const status = useStatus(acts.EDIT_CLUB_MEMBER)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => dispatch(clearStatus(acts.EDIT_CLUB_MEMBER))
  }, [])

  useStatusMsg(status, {
    pending: 'Saving member...',
    error: 'Failed to update club member',
    success: 'Club member updated',
  })

  const onOk = () => {
    dispatch(orgActs.editClubMember(osid, id, member.user.id, role))
      .then(members => {
        if (typeof complete === 'function') {
          complete(members)
        }
      })
      .catch(e => console.log(e))
  }

  return (
    <Modal
      title="Edit member"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={() => completer()}
    >
      <Attr name="User">
        <div>
          {member.user.name}
          <small style={{ display: 'block' }}>{member.user.email}</small>
        </div>
      </Attr>
      <Attr name="Role">
        <div>
          <ClubRoleSelect
            placeholder="Select role"
            style={{ width: '100%' }}
            value={role}
            onChange={e => setRole(e)}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const SingleMemberSelect = ({ user, onChange }) => {
  return (
    <UserSelect
      placeholder="Search users by name"
      style={{ width: '100%' }}
      value={user}
      onChange={onChange}
    />
  )
}

const MultiMemberSelect = ({ onChange }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const update = e => {
    const input = e.target.value
    if (input) {
      const parts = input.split(',').map(i => i.trim().toLowerCase())
      changer([...new Set(parts)]) // remove duplicates
    } else {
      changer([])
    }
  }
  return (
    <div>
      <Input.TextArea onChange={update} />
    </div>
  )
}

const MultiMemberDisplay = ({ users, usersStatusMap, onRemove }) => {
  let elems = null
  if (Array.isArray(users)) {
    elems = users.map(u => {
      let color = 'default'
      if (usersStatusMap.hasOwnProperty(u)) {
        color = usersStatusMap[u].status
      }
      let icon = null
      if (color === 'error') {
        icon = <ExclamationCircleOutlined />
      } else if (color === 'processing') {
        icon = <SyncOutlined spin />
      }
      let tooltip = null
      if (color === 'error') {
        tooltip = usersStatusMap[u].message
      }
      return (
        <Tooltip title={tooltip}>
          <Tag
            key={u}
            closable={typeof onRemove === 'function'}
            onClose={() => onRemove(u)}
            color={color}
            icon={icon}
          >
            {u}
          </Tag>
        </Tooltip>
      )
    })
  }
  const finishedUsers = Object.values(usersStatusMap).reduce((acc, curr) => {
    if (curr.status !== 'processing') {
      return acc + 1
    }
    return acc
  }, 0)

  const percentComplete =
    !Array.isArray(users) || users.length === 0
      ? 0
      : ((finishedUsers / users.length) * 100).toFixed(0)
  return (
    <div>
      <Progress percent={percentComplete} />
      {elems}
    </div>
  )
}

const AddMemberModal = ({ complete }) => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [mode, setMode] = useState('single') // single or multi
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState(null) // a mix of user ids and user emails
  // map that tracks the member creation status of users in multi mode
  // looks like { status: '', message: '' }
  // status can be one of 'processing', 'success', or 'error'. they match the antd <Tag> color values
  const [usersStatusMap, setUsersStatusMap] = useState({})
  const [role, setRole] = useState(roles.Member)
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false)
  const [addingMultiUsers, setAddingMultiUsers] = useState(false)
  const status = useStatus(acts.ADD_CLUB_MEMBER)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => dispatch(clearStatus(acts.ADD_CLUB_MEMBER))
  }, [])

  const onOk = () => {
    const formComplete =
      (mode === 'single' && user !== null) ||
      (mode === 'multi' && Array.isArray(users) && users.length > 0)
    if (!formComplete) {
      message.error('At least one user must be specified')
    }

    setUsersStatusMap({})

    const params = { osid, clubId: id, role, sendWelcomeEmail }
    if (mode === 'multi') {
      setAddingMultiUsers(true)
      Promise.all(
        users.map(u => {
          let act = null
          if (validate(u)) {
            act = orgActs.addClubMember({ ...params, userId: u })
          } else {
            act = orgActs.addClubMember({ ...params, email: u })
          }
          setUsersStatusMap(prev => ({
            ...prev,
            [u]: { ...prev[u], status: 'processing' },
          }))
          return dispatch(act)
            .then(() => {
              setUsersStatusMap(prev => ({
                ...prev,
                [u]: { ...prev[u], status: 'success' },
              }))
            })
            .catch(e => {
              console.log(e)
              setUsersStatusMap(prev => ({
                ...prev,
                [u]: { ...prev[u], status: 'error', message: e.message },
              }))
            })
        }),
      ).then(() => {
        setAddingMultiUsers(false)
      })
    } else {
      dispatch(orgActs.addClubMember({ ...params, userId: user.id }))
        .then(() => exit())
        .catch(e => console.log(e))
    }
  }

  // pulls down updated members and exits modal by calling complete
  const exit = () => {
    dispatch(orgActs.fetchClubMembers({ osid, clubId: id })).then(members => completer(members))
  }

  const onCancel = () => {
    if (mode === 'multi') {
      exit()
    } else {
      completer()
    }
  }

  const onMemberRemove = toRemove => {
    setUsers(prev => prev.filter(u => u !== toRemove))
    setUsersStatusMap(prev =>
      Object.keys(prev).reduce((acc, curr) => {
        if (curr !== toRemove) {
          acc[curr] = prev[curr]
        }
        return acc
      }, {}),
    )
  }
  const onChangeMode = e => {
    setUser(null)
    setUsers(null)
    setUsersStatusMap({})
    setMode(e.target.value)
  }

  return (
    <Modal
      title="Add members"
      visible
      okText={mode === 'single' ? 'Add member' : 'Add members'}
      onOk={onOk}
      okButtonProps={{
        disabled: !!status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
      cancelText={mode === 'single' ? 'Cancel' : 'Done'}
    >
      <div style={{ marginBottom: '1em' }}>
        <Radio.Group onChange={onChangeMode} value={mode}>
          <Radio value="single">Single</Radio>
          <Radio value="multi">Multiple</Radio>
        </Radio.Group>
      </div>
      <Attr
        name={mode === 'single' ? 'User' : 'Users'}
        description={mode === 'multi' ? 'Enter user emails or ids separated by a comma' : null}
      >
        <div style={{ marginTop: '.5em' }}>
          {mode === 'single' && <SingleMemberSelect user={user} onChange={u => setUser(u)} />}
          {mode === 'multi' && (
            <>
              <MultiMemberSelect onChange={usrs => setUsers(usrs)} />
              <div style={{ marginTop: '1em' }}>
                <MultiMemberDisplay
                  users={users}
                  usersStatusMap={usersStatusMap}
                  onRemove={addingMultiUsers ? null : onMemberRemove}
                />
              </div>
            </>
          )}
        </div>
      </Attr>
      <Attr name="Role">
        <div>
          <ClubRoleSelect
            placeholder="Select role"
            style={{ width: '100%' }}
            value={role}
            onChange={e => setRole(e)}
          />
        </div>
      </Attr>
      <Attr>
        <Checkbox checked={sendWelcomeEmail} onChange={e => setSendWelcomeEmail(e.target.checked)}>
          <Label>Send welcome email</Label>
        </Checkbox>
      </Attr>
    </Modal>
  )
}

const ClubPosts = () => {
  const { osid } = useParams()
  const { club } = useContext(ClubContext)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB_POSTS))
      dispatch(clearStatus(acts.FETCH_CLUB_PINNED_POSTS))
      dispatch(clearStatus(acts.PIN_CLUB_POST))
      dispatch(clearStatus(acts.UNPIN_CLUB_POST))
      dispatch(clearStatus(acts.DELETE_CLUB_POST))
    }
  }, [])

  const clubPosts = usePosts(osid, {
    loadPosts: (cursor, limit) => orgActs.fetchClubPosts(osid, club.id, cursor, limit),
    loadPinned: () => orgActs.fetchClubPinnedPosts(osid, club.id),
    pinPost: postId => orgActs.pinClubPost(osid, club.id, postId),
    unpinPost: postId => orgActs.unpinClubPost(osid, club.id, postId),
    deletePost: postId => orgActs.deleteClubPost(osid, club.id, postId),
    editPostPath: postId => paths.org.EDIT_CLUB_POST(osid, club.id, postId),
    viewPostPath: postId => paths.org.CLUB_POST(osid, club.id, postId),
  })

  // this effect loads posts if they're not loaded
  useEffect(() => {
    if (!clubPosts.posts && !clubPosts.pinned) {
      clubPosts.loadPosts()
      clubPosts.loadPinned()
    }
  }, [clubPosts.posts, clubPosts.pinned])

  if (!clubPosts.posts) {
    return <LoadingOutlined />
  }

  return (
    <MediumInfo title="Posts" style={{ marginTop: 0 }}>
      <Posts {...clubPosts} />
    </MediumInfo>
  )
}

export const Video = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid rgb(204, 204, 204);
  padding: 0.25em 0.5em;
  background-color: white;
  margin-bottom: 0.5em;
  max-width: 25em;
`

const ClubVideos = () => {
  const { osid } = useParams()
  const { club } = useContext(ClubContext)
  const [editing, setEditing] = useState(false)
  const dispatch = useDispatch()
  const status = {
    updateOrder: useStatus(acts.UPDATE_CLUB_VIDEOS_ORDER),
  }
  const theme = useContext(ThemeContext)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB_VIDEOS))
      dispatch(clearStatus(acts.UPDATE_CLUB_VIDEOS_ORDER))
    }
  }, [])

  const clubVideos = useVideos({
    getVideos: () => orgActs.fetchClubVideos({ osid, clubId: club.id }),
    updateVideosOrder: videos => orgActs.updateClubVideosOrder({ osid, clubId: club.id, videos }),
    deleteVideo: videoId => orgActs.deleteClubVideo({ osid, clubId: club.id, videoId }),
  })

  // this effect loads videos if they're not loaded
  useEffect(() => {
    if (!clubVideos.videos) {
      clubVideos.loadVideos()
    }
  }, [clubVideos])

  if (!clubVideos.videos) {
    return <LoadingOutlined />
  }

  const onCancelEdit = () => {
    setEditing(false)
  }

  const onSaveEditing = () => {
    const newVideos = [...clubVideos.videos]
    //Restructure the products according to server needs
    newVideos.forEach(v => {
      v.products = v.products.map(product => (product.order ? product.product : product))
    })
    clubVideos.updateOrder(newVideos)
    setEditing(false)
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button
          style={{ marginRight: theme.spacing[2] }}
          disabled={status.updateOrder.pending}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <Cta onClick={onSaveEditing} disabled={status.updateOrder.pending}>
          {status.updateOrder.pending ? 'Saving...' : 'Save'}
        </Cta>
      </div>
    )
  }
  return (
    <MediumInfo title="Videos" style={{ marginTop: 0 }} button={buttons}>
      {editing ? (
        <Reorderable
          items={clubVideos.videos}
          keyExtractor={v => v.id}
          renderItem={v => (
            <Video key={v.id}>
              <span style={{ marginBottom: theme.spacing[1] }}>{v.title}</span>
              <small>{v.videoUrl}</small>
            </Video>
          )}
          onChange={vds => clubVideos.setVideos(vds)}
        />
      ) : (
        <Videos {...clubVideos} />
      )}
    </MediumInfo>
  )
}

const Container = ({ children }) => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const { club, setClub } = useContext(ClubContext)
  const [addingMember, setAddingMember] = useState(false)
  const status = {
    deleteClub: useStatus(acts.DELETE_CLUB),
  }

  let tab = match.path.substring(match.path.lastIndexOf('/') + 1)
  const availableTabs = ['overview', 'posts', 'programs', 'shop', 'members', 'videos', 'settings']
  if (!availableTabs.includes(tab)) {
    tab = 'overview'
  }

  useStatusMsg(status.deleteClub, {
    pending: 'Deleting club...',
    error: 'Failed to delete club',
    success: 'Club deleted',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.DELETE_CLUB))
    }
  }, [])

  const deleteClub = () => {
    return dispatch(orgActs.deleteClub(osid, id)).then(() => history.push(paths.org.CLUBS(osid)))
  }

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      history.push(paths.org.EDIT_CLUB(osid, id))
    } else if (e.key === 'add-member') {
      setAddingMember(true)
    } else if (e.key === 'new-post') {
      history.push(paths.org.NEW_CLUB_POST(osid, id))
    } else if (e.key === 'new-video') {
      history.push(paths.org.NEW_CLUB_VIDEO(osid, id))
    } else if (e.key === 'delete-club') {
      Modal.confirm({
        title: `Permanently delete club?`,
        content: `This permanently deletes a club along with its memberships (but not users),
                  posts, and locations. Programs related to this club are not deleted and will return to the org.`,
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => deleteClub(),
      })
    }
  }

  const handleExportActionMenu = e => {
    if (e.key === 'members') {
      dispatch(orgActs.downloadClubMembersCsv(osid, id)).then(data => {
        downloadDataAsFile({
          data,
          filename: `${club.name}-members-${moment(new Date()).format('YYYY-M-D')}.csv`,
          filetype: 'text/csv;charset=utf-8;',
        })
      })
    }
  }
  const exportActionMenu = (
    <Menu onClick={handleExportActionMenu}>
      <Menu.Item key="members"> Members CSV</Menu.Item>
    </Menu>
  )

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="add-member">Add members</Menu.Item>
      <Menu.Item key="new-post">New post</Menu.Item>
      <Menu.Item key="new-video">New video</Menu.Item>
      <Menu.Item key="delete-club" danger>
        Delete club
      </Menu.Item>
    </Menu>
  )
  let display = <LoadingOutlined />
  if (club) {
    const eventsTitle = (
      <Link to={paths.org.CLUB_PROGRAMS(osid, id)}>
        <BadgeTitle
          title={features.program.name.plural}
          count={club.programs ? club.programs.length : 0}
        />
      </Link>
    )
    const shopTitle = (
      <Link to={paths.org.CLUB_SHOP(osid, id)}>
        <BadgeTitle
          title={features.shop.name.singular}
          count={club.products ? club.products.length : 0}
        />
      </Link>
    )
    const memberTitle = (
      <Link to={paths.org.CLUB_MEMBERS(osid, id)}>
        <BadgeTitle title="Members" count={club.members ? club.members.length : 0} />
      </Link>
    )
    display = (
      <>
        <Head>
          <Title>{club.name}</Title>
          <Tag>{club.isPublic ? 'Public' : 'Private'}</Tag>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ display: 'flex' }}>
            <Dropdown overlay={exportActionMenu} trigger={['click']} placement="bottomRight">
              <Btn style={{ marginRight: '.5em' }}>
                <ExportOutlined style={{ fontSize: '14px' }} /> Export
              </Btn>
            </Dropdown>
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Cta>
                Actions <DownOutlined />
              </Cta>
            </Dropdown>
          </div>
        </Head>
        <a
          target="_blank"
          href={urlUtils.mainSiteClub(club.sid)}
          style={{ display: 'inline-block', marginBottom: '.5em' }}
        >
          {urlUtils.mainSiteClub(club.sid)}
        </a>
        <Tabs activeKey={tab} animated={false}>
          <TabPane
            key="overview"
            tab={<Link to={paths.org.CLUB(osid, id)}>Overview</Link>}
          ></TabPane>
          <TabPane
            key="posts"
            tab={<Link to={paths.org.CLUB_POSTS(osid, id)}>Posts</Link>}
          ></TabPane>
          <TabPane key="programs" tab={eventsTitle}></TabPane>
          <TabPane key="shop" tab={shopTitle}></TabPane>
          <TabPane key="members" tab={memberTitle}></TabPane>
          <TabPane
            key="videos"
            tab={<Link to={paths.org.CLUB_VIDEOS(osid, id)}>Videos</Link>}
          ></TabPane>
          <TabPane
            key="settings"
            tab={<Link to={paths.org.CLUB_SETTINGS(osid, id)}>Settings</Link>}
          ></TabPane>
        </Tabs>
        {children}
      </>
    )
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.CLUBS(osid)}>{features.club.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{club ? club.name : id}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
      {addingMember && (
        <AddMemberModal
          complete={members => {
            if (members) {
              setClub(prev => ({ ...prev, members }))
            }
            setAddingMember(false)
          }}
        />
      )}
    </PaddedContainer>
  )
}

/**
 * We're hiding the program query concept from the club ui.
 * So we parse the club to grab the programs and orgs from the program query
 * and put it under `club.programs` and `club.orgs` in order to simplify the ui
 */
export const parseClub = club => {
  // Create a Set to store unique program IDs
  const seenIds = new Set()
  const allPrograms = [
    ...(club.programQuery?.programs?.map(p => p.program) ?? []),
    ...(club.programs?.map(p => p.program) ?? []),
  ].filter(program => {
    if (!program || seenIds.has(program.id)) {
      return false
    }
    seenIds.add(program.id)
    return true
  })

  const orgs = club.programQuery?.orgs?.map(o => o.org) ?? []

  return { ...club, programs: allPrograms, orgs }
}

const Club = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [club, setClub] = useState(null)
  const status = useStatus(acts.FETCH_CLUB)

  useEffect(() => {
    dispatch(orgActs.fetchClub(osid, id)).then(c => setClub(parseClub(c)))
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB))
    }
  }, [id])

  useStatusMsg(status, {
    error: e => `Error: ${e.err.message}`,
  })

  if (!status.success) {
    return (
      <ClubContext.Provider value={{ club, setClub }}>
        <Container>
          <LoadingOutlined />
        </Container>
      </ClubContext.Provider>
    )
  }

  return (
    <ClubContext.Provider value={{ club, setClub }}>
      <Switch>
        <Route
          exact
          path={patterns.org.CLUB_OVERVIEW()}
          component={() => (
            <Container>
              <Overview />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_POSTS()}
          component={() => (
            <Container>
              <ClubPosts />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_PROGRAMS()}
          component={() => (
            <Container>
              <Programs />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_SHOP()}
          component={() => (
            <Container>
              <ClubShop />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_MEMBERS()}
          component={() => (
            <Container>
              <Members />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_VIDEOS()}
          component={() => (
            <Container>
              <ClubVideos />
            </Container>
          )}
        />
        <Route
          exact
          path={patterns.org.CLUB_SETTINGS()}
          component={() => (
            <Container>
              <ClubSettings />
            </Container>
          )}
        />
      </Switch>
    </ClubContext.Provider>
  )
}

export default Club
